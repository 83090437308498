// UseCaseTemplateEditor.tsx
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Checkbox, InputNumber, Upload, Select } from "antd";
import "./UseCaseTemplateEditor.scss";
import { RcFile } from "antd/lib/upload/interface";
import { IJSONObject } from "@blings/blings-player";
import {
  ExtractedValues,
  getTypesFromLiveControl,
} from "../../../utils/getTypesFromLiveControl";
import { MSTContext } from "../../../stores/Root";
import { FontInput } from "../../../API";
import { BlingsInput } from "../../../components/BlingsInput/BlingsInput";
import {
  font2Hash,
  FontFamily,
  FONT_LIBRARY,
  weight2Name,
} from "../../../utils/font";
import { observer } from "mobx-react-lite";
import { useDebounceCallback } from "../../../helpers/DebounceHook";

export const UseCaseTemplateEditor = observer(
  ({
    handleLiveControlChange,
    liveControl,
    projectId,
    accountId,
    setIsSavingTemplate,
    overlayAudio = { url: "", loop: true, volume: 1 },
    handleOverlayAudioChange,
    isUseCasePage = true,
    fontsInVideoParts,
    fontOverrides,
    setFontOverrides,
  }: {
    handleLiveControlChange: React.Dispatch<React.SetStateAction<IJSONObject>>;
    liveControl: IJSONObject;
    projectId?: string;
    accountId?: string;
    setIsSavingTemplate: React.Dispatch<React.SetStateAction<boolean>>;
    overlayAudio?: {
      url: string;
      loop: boolean;
      volume: number;
    };
    fontsInVideoParts: Array<FontInput>;
    fontOverrides: Array<FontInput>;
    setFontOverrides: (changes: Array<FontInput>) => void;
    handleOverlayAudioChange: React.Dispatch<
      React.SetStateAction<{
        url: string;
        loop: boolean;
        volume: number;
      }>
    >;
    isUseCasePage?: boolean;
  }) => {
    const { uploadAssetToProject } = useContext(MSTContext).liveControlStore;
    const [isImageUploading, setIsImageUploading] = useState(false);
    const [liveControlKeysSeparatedByType, setLiveControlKeysSeparatedByType] =
      useState<ExtractedValues | null>(null);

    const debouncedHandleColorChange = useDebounceCallback(
      (key: string, color: string) => {
        handleLiveControlChange({ [key]: color });
      },
      100
    );

    useEffect(() => {
      if (liveControlKeysSeparatedByType) return;
      const lcsbk = getTypesFromLiveControl(liveControl);
      setLiveControlKeysSeparatedByType(lcsbk);
    }, [liveControl, liveControlKeysSeparatedByType]);

    // Memoize the derived video-part font families.
    const fontsInVideoPartsAsFontFamily = useMemo(() => {
      return fontInVideo2NestedFontFamily(fontsInVideoParts);
    }, [fontsInVideoParts]);

    // Build the combined array with FONT_LIBRARY prioritized.
    const allFontFamilies = useMemo(() => {
      return [
        ...FONT_LIBRARY,
        ...fontsInVideoPartsAsFontFamily.filter(
          (font) =>
            !FONT_LIBRARY.some(
              (libFont) => libFont.fontFamily === font.fontFamily
            )
        ),
      ];
    }, [fontsInVideoPartsAsFontFamily]);

    // Create grouped options for the font family Select.
    const groupedFontOptions = useMemo(() => {
      const videoOptions = fontsInVideoPartsAsFontFamily.map((font) => ({
        label: font.fontFamily,
        value: font.fontFamily,
      }));
      const libraryOptions = FONT_LIBRARY.map((font) => ({
        label: font.fontFamily,
        value: font.fontFamily,
      }));
      const groups = [];
      if (videoOptions.length) {
        groups.push({ label: "Video Fonts", options: videoOptions });
      }
      if (libraryOptions.length) {
        groups.push({ label: "Fonts Library", options: libraryOptions });
      }
      return groups;
    }, [fontsInVideoPartsAsFontFamily]);

    // Local state: selected font families (for filtering variant options)
    // and selected variant URLs (the replacement target).
    const overridesAsHash = fontOverrides.reduce<Record<string, FontInput>>(
      (acc, font) => {
        acc[font2Hash(font)] = font;
        return acc;
      },
      {}
    );
    const selectedUrls = fontsInVideoParts.map((font) => {
      const fontInVideoPartHash = font2Hash(font);
      const override = overridesAsHash[fontInVideoPartHash];
      if (override) {
        return override.url;
      }
      return font.url;
    });

    const [selectedVariantUrls, setSelectedVariantUrls] =
      useState<string[]>(selectedUrls);

    const fontFamilyFromUrl = (url: string) => {
      for (let i = 0; i < allFontFamilies.length; i++) {
        const fontFamily = allFontFamilies[i];
        for (let j = 0; j < fontFamily.variants.length; j++) {
          const variant = fontFamily.variants[j];
          if (variant.url === url) {
            return fontFamily.fontFamily;
          }
        }
      }
      return "";
    };

    const [selectedFontFamilies, setSelectedFontFamilies] = useState<string[]>(
      selectedUrls.map(fontFamilyFromUrl)
    );

    // Compute and update fontOverrides.
    // Here we keep the original font data (family, weight, style) and override only the URL.
    useEffect(() => {
      const newOverrides = fontsInVideoParts.reduce(
        (acc, originalFont, index) => {
          // If the replacement URL is the same as the original, no override is needed.
          if (selectedVariantUrls[index] === originalFont.url) {
            return acc;
          }
          acc.push({
            family: originalFont.family,
            weight: originalFont.weight,
            style: originalFont.style,
            url: selectedVariantUrls[index],
          });
          return acc;
        },
        [] as FontInput[]
      );

      // Only update if there is an actual change.
      if (JSON.stringify(newOverrides) !== JSON.stringify(fontOverrides)) {
        setFontOverrides(newOverrides);
      }
    }, [
      selectedVariantUrls,
      fontsInVideoParts,
      fontOverrides,
      setFontOverrides,
    ]);

    // Handler for when the font family is changed.
    // (Used for filtering the available variant options.)
    const handleFamilyChange = (index: number, newFamily: string) => {
      const updatedFamilies = [...selectedFontFamilies];
      updatedFamilies[index] = newFamily;
      setSelectedFontFamilies(updatedFamilies);

      // Get available variants for the new family.
      const newVariants = getVariantsFromFontFamily(newFamily, allFontFamilies);
      // Choose a default variant:
      // If the new family is the same as the original, keep the original variant;
      // otherwise, default to the first available variant.
      const defaultVariantUrl =
        newFamily === fontsInVideoParts[index].family
          ? fontsInVideoParts[index].url
          : newVariants[0]?.url || "";
      const updatedVariants = [...selectedVariantUrls];
      updatedVariants[index] = defaultVariantUrl;
      setSelectedVariantUrls(updatedVariants);
    };

    // Handler for when the variant (replacement URL) is changed.
    const handleVariantChange = (index: number, newVariantUrl: string) => {
      const updatedVariants = [...selectedVariantUrls];
      updatedVariants[index] = newVariantUrl;
      setSelectedVariantUrls(updatedVariants);
    };

    // Helper: get available variants for a given font family.
    const getVariantOptions = (family: string) =>
      getVariantsFromFontFamily(family, allFontFamilies);
    return (
      <div className="customize-use-case-content">
        {isUseCasePage && (
          <div className="customize-use-case-title">
            <div className="title">Customize Your Use Case</div>
            <div className="subtitle">
              Modify the settings below to reflect your brand
            </div>
          </div>
        )}
        <div className="customize-template">
          {liveControlKeysSeparatedByType && (
            <div className="brand-customization-wrapper">
              {liveControlKeysSeparatedByType.images.map((key, index) => (
                <div key={`image-${index}`}>
                  <div className="logo-upload-wrapper">
                    <Upload
                      multiple={false}
                      disabled={isImageUploading}
                      className="container-file-upload"
                      accept="image/png, image/jpeg, image/webp"
                      showUploadList={false}
                      customRequest={({ file, onError, onSuccess }) => {
                        const f = file as RcFile;
                        setIsImageUploading(true);
                        setIsSavingTemplate(true);
                        uploadAssetToProject(
                          f,
                          {
                            s3Folder: "image",
                            projectId:
                              projectId && accountId
                                ? `${projectId}_${accountId}`
                                : undefined,
                          },
                          (s3Key: string) => {
                            handleLiveControlChange({ [key]: s3Key });
                            onSuccess && onSuccess(file);
                            setIsImageUploading(false);
                            setIsSavingTemplate(false);
                          }
                        );
                      }}
                    >
                      {isImageUploading ? (
                        <span className="loader"></span>
                      ) : (
                        <label className="custom-file-upload">
                          <img src={liveControl[key] as string} alt="Preview" />
                        </label>
                      )}
                    </Upload>
                    <div className="hover-file-upload">
                      <span className="title">Upload {toTitleCase(key)}</span>
                      <span className="subtitle">
                        Supported formats: PNG, JPG, WEBP
                      </span>
                    </div>
                  </div>
                </div>
              ))}

              {liveControlKeysSeparatedByType.colors.length > 0 && (
                <div className="color-item-container">
                  {liveControlKeysSeparatedByType.colors.map((key, index) => (
                    <div
                      className="color-item-content"
                      key={`color-item-content-${index}`}
                    >
                      <span>{toTitleCase(key)}</span>
                      <div key={`color-${index}`} className="color-item">
                        <input
                          style={{
                            backgroundColor: liveControl[key] as string,
                          }}
                          type="color"
                          value={liveControl[key] as string}
                          onChange={(e) => {
                            const newColor = e.target.value;
                            debouncedHandleColorChange(key, newColor);
                          }}
                        />
                        <label>{liveControl[key]}</label>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {liveControlKeysSeparatedByType.texts.length > 0 && (
                <div className="text-item-container">
                  {liveControlKeysSeparatedByType.texts.map((key, index) => (
                    <div
                      className="text-item-content"
                      key={`text-item-content-${index}`}
                    >
                      <span>{toTitleCase(key)}</span>
                      <BlingsInput
                        value={liveControl[key] as string}
                        onChange={(value) => {
                          handleLiveControlChange({ [key]: value });
                        }}
                      />
                    </div>
                  ))}
                </div>
              )}

              <div className="form-item">
                <h4 className="card-text">Audio:</h4>
                <div className="input-item">
                  <BlingsInput
                    style={{ maxHeight: "64px" }}
                    value={overlayAudio.url}
                    uploadAssetsToProject={uploadAssetToProject}
                    onChange={(url) => {
                      handleOverlayAudioChange({ ...overlayAudio, url });
                    }}
                    uploadMimeType="audio/mp3"
                  />
                </div>
                {overlayAudio.url && (
                  <div className="audio-controls">
                    <div
                      className="form-item"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <label>
                        Loop:
                        <Checkbox
                          style={{ marginLeft: "10px" }}
                          checked={
                            overlayAudio.loop != null ? overlayAudio.loop : true
                          }
                          onChange={(e) => {
                            handleOverlayAudioChange({
                              ...overlayAudio,
                              loop: e.target.checked,
                            });
                          }}
                        />
                      </label>
                    </div>
                    <div className="form-item-audio-control">
                      <label>Volume:</label>
                      <InputNumber
                        min={0}
                        max={100}
                        step={1}
                        value={(overlayAudio?.volume || 1) * 100}
                        onChange={(value) => {
                          handleOverlayAudioChange({
                            ...overlayAudio,
                            volume: (value || 100) / 100,
                          });
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="font-override-section">
                <h4>Fonts:</h4>
                {fontsInVideoParts.map((fontVideo, index) => {
                  const variantOptions = getVariantOptions(
                    selectedFontFamilies[index]
                  );
                  return (
                    <div key={index} className="font-override-item">
                      <div style={{ display: "flex", marginBottom: "10px" }}>
                        {/* Font Family Select with grouped options */}
                        <Select
                          value={selectedFontFamilies[index]}
                          style={{ width: 200 }}
                          placeholder="Select a font family"
                          options={groupedFontOptions}
                          onChange={(newFamily) =>
                            handleFamilyChange(index, newFamily)
                          }
                        />
                        {/* Font Variant Select (selects the replacement URL) */}
                        <Select
                          style={{ width: 200, marginLeft: "10px" }}
                          value={selectedVariantUrls[index]}
                          options={variantOptions.map((variant) => ({
                            label: variant.name,
                            value: variant.url,
                          }))}
                          onChange={(newVariantUrl) =>
                            handleVariantChange(index, newVariantUrl)
                          }
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
);

// ─── Helper functions ─────────────────────────────
function fontInVideo2NestedFontFamily(
  fonts: Array<FontInput>
): Array<FontFamily> {
  const fontFamilies = new Map<string, Array<FontFamily["variants"][number]>>();
  fonts.forEach((font) => {
    if (fontFamilies.has(font.family)) {
      fontFamilies
        .get(font.family)
        ?.push({ ...font, name: weight2Name[font.weight] });
    } else {
      fontFamilies.set(font.family, [
        { ...font, name: weight2Name[font.weight], weight: font.weight },
      ]);
    }
  });
  return Array.from(fontFamilies).map(([fontFamily, variants]) => ({
    fontFamily,
    variants,
  }));
}

function getVariantsFromFontFamily(
  selectedFontFamily: string,
  fontFamilies: Array<FontFamily>
): FontFamily["variants"] {
  if (!fontFamilies) return [];
  const found = fontFamilies.find(
    (fontFamily) => fontFamily.fontFamily === selectedFontFamily
  );
  return found ? found.variants : [];
}
function toTitleCase(text: string) {
  return text
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}
