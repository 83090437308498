import { IProjectModel } from "../../stores/project/projectModel";
import { useRef, useState, useEffect, useCallback } from "react";
import { Col, Space, Spin } from "antd";
import {
  IFlowDiagram,
  ILineupEntryPointReference,
  Player,
} from "@blings/blings-player";
import { observer } from "mobx-react-lite";
import {
  ExperimentValues,
  IExperiment,
  ISdkScene,
  ISdkSettings,
} from "@blings/blings-player/lib/src/SDK/sdk.api";
import { IVideoPartFilled } from "@blings/blings-player/lib/src/fetchData/getVideoParts";
import { AsyncOpState } from "../../types/enums/async-op-states";
import PlayerManager from "../../utils/playerManager";
import { getENV } from "../../config";
import GlobalMuteButton from "../../components/Icons/GlobalMuteButton";
import { useParams } from "react-router-dom";
import MuterSingleton from "../../utils/mute";

type Props = {
  project: IProjectModel;
  data: string;
  settings?: Partial<ISdkSettings>;
  scene?: string;
};
interface InnerProps extends Props {
  scenes?: ISdkScene[];
  vertical: boolean;
  settings: any;
}
const DEFAULT_POSTERFRAME = 3;
let player: Player | null = null;

// Keep track of scene changes to be able to reset the startFrame when there is a change
let lastScene: any = "";
let mute = true;
function getFirstLineupId(
  flowDiagram: IFlowDiagram
): ILineupEntryPointReference {
  const lineupNode = flowDiagram?.nodes.find(
    (node) => node.lineupId !== undefined
  );
  return { lineup: lineupNode?.lineupId || "" };
}
/**
 * Component to render the Blings Player using dynamic data
 * @param props - All the data in one object. This data is:
 * - project: IProjectModel; - The project model containing the project ID.
 * - data: string; - The data of the dynamic data for the player.
 * - scenes?: string[]; - The scenes for the video that the player must load.
 * - settings?: Partial<ISdkSettings>; - The settings for the player.
 * @returns {React.FC<LinkProps>} - A div component where the player will be rendered.
 */
const DemoSdkPlayerInner = observer(
  ({ project, data, scenes, settings }: InnerProps) => {
    const { id, scene: sceneFromUrl } = useParams<{
      id: string;
      scene: string;
    }>();
    // This is the container reference to where the player will be rendered
    // This will reference the div element that is always rendered
    // And the player SDK will be rendered inside this div
    const containerRef = useRef<HTMLDivElement>(null);
    // New state to track if the player is ready
    const [playerReady, setPlayerReady] = useState(false);
    // This flag will be used to control if the player can be created or not
    // If a player is already created or does not exist, this flag will be set to true
    // If a player is being created, this flag will be set to false
    const canCreatePlayer = useRef(true);
    // Create a ref to store the event handler
    const onFirstPlayRef = useRef<() => void>();
    const onFirstPlaySecondRef = useRef<() => void>();

    // Extract complex expressions
    const serializedExperiments = JSON.stringify(
      project.experiments?.changedDraftExperiments
    );

    // This function will be used to create the player with the actual configuration
    const createPlayer = useCallback(async () => {
      if (!canCreatePlayer.current) {
        return;
      }
      canCreatePlayer.current = false; // So two players won't be created at the same time
      let startFrame: number = settings?.posterFrame || DEFAULT_POSTERFRAME; // Initialize startFrame from posterFrame
      // Check if a player already exists
      if (player) {
        mute = player.muted; // Get mute state from previous player
        startFrame = player.animation.currentFrame || startFrame; // Get frame from previous player
        try {
          // If a player already exists, we want to destroy it
          if ((window as any).p) {
            (window as any).p.destroy();
            (window as any).p = null;
          }
          player = null;
        } catch (e) {
          console.error("err destroying player", e);
        }
      }
      // Create the object that will be used to create the player
      if (containerRef.current && scenes?.length && scenes[0]) {
        containerRef.current.innerHTML = "";

        // Go through the videoParts and create the updatedScenes Object
        const updatedScenes: IVideoPartFilled[] = [];
        project.workspaceVideoParts?.forEach((videoPart) => {
          updatedScenes.push({
            name: videoPart.name,
            json: videoPart.jsonData,
            modsArr:
              videoPart.modsArr?.map((mod: any) => {
                return JSON.parse(mod.dataStr);
              }) || [],
          });
        });
        try {
          if (updatedScenes.length) {
            // Get the major version of the player to use
            // project.playerVersionToUse = "3.1.1" | undefined
            let playerVersion = -1;
            try {
              playerVersion = parseInt(
                (project.playerVersionToUse as string).split(".")[0]
              );
            } catch (e) {}
            // create the Player
            player = await PlayerManager.get().createPlayer(
              {
                project: {
                  env: getENV(),
                  projectId: project.id,
                  videoParts: updatedScenes,
                  liveControlData: project.settingsDraftInEdit,
                },
                experiments: JSON.parse(
                  serializedExperiments
                ) as IExperiment<ExperimentValues>[],
                data,
                variantSelectionSet: project?.experiments?.selectedVariantSet,
                settings: {
                  container: containerRef.current,
                  ...settings,
                  autoplay: true,
                  overlay: JSON.parse(
                    JSON.stringify(project.overlayDraftInEdit)
                  ),
                  fontOverrides: JSON.parse(
                    JSON.stringify(project.fontOverridesDraftInEdit)
                  ),
                },
                scenes,
                flowDiagram: project.flowDiagramSnapshot,
              },
              {
                playerMajorVersion: playerVersion,
              }
            );

            if ((window as any).p) (window as any).p?.destroy();
            if (player) {
              const globalMute = MuterSingleton.instance.isMuting();
              onFirstPlayRef.current = () => {
                if (globalMute && mute === true) {
                  player?.mute();
                } else {
                  player?.unmute();
                }
              };
              player.EE.on("onFirstPlay", onFirstPlayRef.current);
            }

            (window as any).p = player;
            if (
              startFrame !== DEFAULT_POSTERFRAME &&
              startFrame !== settings?.posterFrame &&
              scenes[0] === lastScene
            ) {
              onFirstPlaySecondRef.current = () => {
                player?.hideCover();
                if (player) {
                  if (!mute) player.unmute();
                  player.seekTo(startFrame);
                  player.pausedByUser = true;
                }
              };
              player.EE.on("onFirstPlay", onFirstPlaySecondRef.current);
              player.pausedByUser = true;
            }
            // Save Scene name to verify if we changed scenes when the next player is created
            lastScene = scenes[0];
            canCreatePlayer.current = true; // Allow creating player again after setup
            setPlayerReady(true); // Mark player as ready
          }
        } catch (e) {
          console.error(e);
          canCreatePlayer.current = true; // Ensure the flag is reset in case of error
          setPlayerReady(false); // Optionally, reset playerReady in case of error
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      scenes,
      serializedExperiments,
      project.settingsDraftInEdit,
      project.workspaceVideoParts,
      sceneFromUrl,
      project.fontOverridesDraftInEdit,
      project.overlayDraftInEdit,
    ]);

    // This will be used to control if the player needs a new configuration, so a new player needs to be created
    useEffect(() => {
      createPlayer();
      return () => {
        // Capture the current ref values
        const firstPlayHandler = onFirstPlayRef.current;
        const secondPlayHandler = onFirstPlaySecondRef.current;

        if ((window as any).p) {
          // Remove the first event listener
          if (firstPlayHandler && player) {
            player.EE.off("onFirstPlay", firstPlayHandler);
          }
          // Remove the second event listener
          if (secondPlayHandler && player) {
            player.EE.off("onFirstPlay", secondPlayHandler);
          }
          // Destroy the player
          (window as any).p?.destroy();
        }
        (window as any).p = null;
      };
    }, [
      createPlayer,
      project,
      project.id,
      project.workspaceVideoParts,
      project.experiments?.changedDraftExperiments,
      scenes,
      data,
      settings,
      sceneFromUrl,
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ]);

    return (
      <>
        <div ref={containerRef} style={{ borderRadius: "8px" }} />
        {playerReady && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              width: "100%",
              marginTop: "10px",
            }}
          >
            <GlobalMuteButton color="#1b2b4b" />
          </div>
        )}
      </>
    );
  }
);

export const NewSdkPlayer = observer(
  ({ project, data, settings, scene }: Props) => {
    const { id, scene: sceneFromUrl } = useParams<{
      id: string;
      scene: string;
    }>();
    const currentScene = project.workspaceVideoParts?.find(
      (vp) => vp.name && scene === vp.name
    );
    const finalScenes =
      sceneFromUrl === "_branding" || sceneFromUrl == null
        ? [getFirstLineupId(project.flowDiagramSnapshot as IFlowDiagram)]
        : [scene];

    const vertical = currentScene
      ? currentScene?.jsonData?.h > currentScene?.jsonData?.w
      : false;
    if (!(finalScenes.length && finalScenes[0])) return <></>;
    return (
      <Col
        lg={{ span: 16, order: 2 }}
        xs={{ span: 24, order: 1 }}
        className={vertical ? "Vertical" : "Horizontal"}
      >
        <Space
          direction="vertical"
          style={{
            aspectRatio: " auto 9 / 16",
            display: "flex",
            width: vertical ? "auto" : "100%",
            maxHeight: "calc(100vh - 220px)",
          }}
        >
          {project.refreshModsStatus === AsyncOpState.Saving ? (
            <Spinner timeout={5000} />
          ) : (currentScene && currentScene.jsonData && scene) ||
            sceneFromUrl ? (
            <DemoSdkPlayerInner
              project={project}
              data={data}
              scenes={finalScenes as ISdkScene[]}
              vertical={vertical}
              settings={settings}
              //playerVersion={project.playerVersion}
            />
          ) : (
            <div className="placeholder">
              <p>Select a content item to preview its scene</p>
            </div>
          )}
        </Space>
      </Col>
    );
  }
);

/**
 * Create a spinner component with a timeout to hide it
 * @param props The props of the DemoPage component
 * - timeout: The timeout for the spinner to be shown
 * @returns The Spinner component
 */
function Spinner({ timeout }: { timeout: number }) {
  const [spinnerTimeout, _] = useState(timeout);
  const [shouldSpin, setShouldSpin] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldSpin(false);
    }, spinnerTimeout);
    return () => {
      clearTimeout(timer);
    };
  }, [spinnerTimeout]);
  return (
    <div>
      {shouldSpin ? (
        <div
          style={{
            display: "flex",
            alignSelf: "center",
            justifyContent: "space-around",
            marginTop: "30vh",
          }}
        >
          <Spin />
        </div>
      ) : null}
    </div>
  );
}
