import { UploadFile } from "antd";
import { FontInput } from "../API";

export function isFont(file: File | UploadFile<File>) {
  if (!file.type) return false;
  return file.type.split("/")[1] === "font";
}

export const FONT_LIBRARY: Array<FontFamily> = [
  {
    fontFamily: "Abril Fatface",
    variants: [
      {
        name: "Regular",
        weight: "400",
        style: "normal",
        url: "https://fonts.gstatic.com/s/abrilfatface/v23/zOL64pLDlL1D99S8g8PtiKchq-dmjcDidBc.woff2",
      },
    ],
  },
  {
    fontFamily: "Assistant",
    variants: [
      {
        name: "Regular",
        weight: "400",
        style: "normal",
        url: "https://fonts.gstatic.com/s/assistant/v19/2sDcZGJYnIjSi6H75xkzaGW5Kb8VZA.woff2",
      },
    ],
  },
  {
    fontFamily: "Barlow",
    variants: [
      {
        name: "Bold",
        weight: "700",
        style: "normal",
        url: "https://fonts.gstatic.com/s/barlow/v12/7cHsv4kjgoGqM7E_CfOA5WouvToJdLm8.woff2",
      },
      {
        name: "Light",
        weight: "300",
        style: "normal",
        url: "https://fonts.gstatic.com/s/barlow/v12/7cHsv4kjgoGqM7E_CfOQ4mouvToJdLm8.woff2",
      },
      {
        name: "Regular",
        weight: "400",
        style: "normal",
        url: "https://fonts.gstatic.com/s/barlow/v12/7cHrv4kjgoGqM7E_Cfs7wH8Dnzcj.woff2",
      },
    ],
  },
  {
    fontFamily: "Bebas Neue",
    variants: [
      {
        name: "Regular",
        weight: "400",
        style: "normal",
        url: "https://fonts.gstatic.com/s/bebasneue/v14/JTUSjIg69CK48gW7PXoo9WlhyyTh89Y.woff2",
      },
    ],
  },
  {
    fontFamily: "Crushed",
    variants: [
      {
        name: "Regular",
        weight: "400",
        style: "normal",
        url: "https://fonts.gstatic.com/s/crushed/v30/U9Mc6dym6WXImTlFf14VuaixPzE.woff2",
      },
    ],
  },
  {
    fontFamily: "Heebo",
    variants: [
      {
        name: "Regular",
        weight: "400",
        style: "normal",
        url: "https://fonts.gstatic.com/s/heebo/v26/NGS6v5_NC0k9P9H0TbFhsqMA6aw.woff2",
      },
    ],
  },
  {
    fontFamily: "Inter",
    variants: [
      {
        name: "Regular",
        weight: "400",
        style: "normal",
        url: "https://fonts.gstatic.com/s/inter/v18/UcCm3FwrK3iLTcvnUwQT9mI1F54.woff2",
      },
    ],
  },
  {
    fontFamily: "Lora",
    variants: [
      {
        name: "Bold",
        weight: "700",
        style: "normal",
        url: "https://fonts.gstatic.com/s/lora/v35/0QIvMX1D_JOuMwr7I_FMl_E.woff2",
      },
    ],
  },
  {
    fontFamily: "Noto Sans",
    variants: [
      {
        name: "Bold",
        weight: "700",
        style: "normal",
        url: "https://fonts.gstatic.com/s/notosans/v38/o-0ZIpQlx3QUlC5A4PNr4C5OaxRsfNNlKbCePevtuXOmHS91iw.woff2",
      },
    ],
  },
  {
    fontFamily: "Nunito",
    variants: [
      {
        name: "Regular",
        weight: "400",
        style: "normal",
        url: "https://fonts.gstatic.com/s/nunito/v26/XRXV3I6Li01BKofINeaBTMnFcQ.woff2",
      },
    ],
  },
  {
    fontFamily: "Open Sans",
    variants: [
      {
        name: "Regular",
        weight: "400",
        style: "normal",
        url: "https://fonts.gstatic.com/s/opensans/v40/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-mu0SC55I.woff2",
      },
    ],
  },
  {
    fontFamily: "Playfair Display",
    variants: [
      {
        name: "Bold",
        weight: "700",
        style: "normal",
        url: "https://fonts.gstatic.com/s/playfairdisplay/v37/nuFiD-vYSZviVYUb_rj3ij__anPXDTzYgEM86xQ.woff2",
      },
    ],
  },
  {
    fontFamily: "Poppins",
    variants: [
      {
        name: "Thin",
        weight: "100",
        style: "normal",
        url: "https://fonts.gstatic.com/s/poppins/v22/pxiGyp8kv8JHgFVrLPTucHtAOvWDSA.woff2",
      },
      {
        name: "Extra Light",
        weight: "200",
        style: "normal",
        url: "https://fonts.gstatic.com/s/poppins/v22/pxiByp8kv8JHgFVrLFj_Z1xlFd2JQEk.woff2",
      },
      {
        name: "Light",
        weight: "300",
        style: "normal",
        url: "https://fonts.gstatic.com/s/poppins/v22/pxiByp8kv8JHgFVrLDz8Z1xlFd2JQEk.woff2",
      },
      {
        name: "Normal",
        weight: "400",
        style: "normal",
        url: "https://fonts.gstatic.com/s/poppins/v22/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2",
      },
      {
        name: "Medium",
        weight: "500",
        style: "normal",
        url: "https://fonts.gstatic.com/s/poppins/v22/pxiByp8kv8JHgFVrLGT9Z1xlFd2JQEk.woff2",
      },
      {
        name: "Semi Bold",
        weight: "600",
        style: "normal",
        url: "https://fonts.gstatic.com/s/poppins/v22/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff2",
      },
      {
        name: "Bold",
        weight: "700",
        style: "normal",
        url: "https://fonts.gstatic.com/s/poppins/v22/pxiByp8kv8JHgFVrLCz7Z1xlFd2JQEk.woff2",
      },
      {
        name: "Extra Bold",
        weight: "800",
        style: "normal",
        url: "https://fonts.gstatic.com/s/poppins/v22/pxiByp8kv8JHgFVrLDD4Z1xlFd2JQEk.woff2",
      },
      {
        name: "Black",
        weight: "900",
        style: "normal",
        url: "https://fonts.gstatic.com/s/poppins/v22/pxiByp8kv8JHgFVrLBT5Z1xlFd2JQEk.woff2",
      },
    ],
  },
  {
    fontFamily: "Roboto",
    variants: [
      {
        name: "Regular",
        weight: "400",
        style: "normal",
        url: "https://fonts.gstatic.com/s/roboto/v47/KFO7CnqEu92Fr1ME7kSn66aGLdTylUAMa3yUBHMdazQ.woff2",
      },
    ],
  },
  {
    fontFamily: "Rubik",
    variants: [
      {
        name: "Regular",
        weight: "400",
        style: "normal",
        url: "https://fonts.gstatic.com/s/rubik/v28/iJWKBXyIfDnIV7nBrXyw023e.woff2",
      },
    ],
  },
  {
    fontFamily: "Ubuntu",
    variants: [
      {
        name: "Medium",
        weight: "500",
        style: "normal",
        url: "https://fonts.gstatic.com/s/ubuntu/v20/4iCv6KVjbNBYlgoCjC3jsGyNPYZvgw.woff2",
      },
      {
        name: "Bold",
        weight: "700",
        style: "normal",
        url: "https://fonts.gstatic.com/s/ubuntu/v20/4iCv6KVjbNBYlgoCxCvjsGyNPYZvgw.woff2",
      },
      {
        name: "Light",
        weight: "300",
        style: "normal",
        url: "https://fonts.gstatic.com/s/ubuntu/v20/4iCv6KVjbNBYlgoC1CzjsGyNPYZvgw.woff2",
      },
      {
        name: "Regular",
        weight: "400",
        style: "normal",
        url: "https://fonts.gstatic.com/s/ubuntu/v20/4iCs6KVjbNBYlgoKfw72nU6AFw.woff2",
      },
    ],
  },
];

export function font2Hash(font: FontInput): string {
  return `${font.family}#${font.weight}#${font.style}`;
}
export function flattenFontLibrary(fonts: Array<FontFamily>): FontInput[] {
  return fonts.flatMap((font) =>
    font.variants.map((variant) => ({
      family: font.fontFamily,
      weight: variant.weight.toString(),
      style: variant.style,
      url: variant.url,
    }))
  );
}
export type FontFamily = {
  fontFamily: string;
  variants: Array<{
    name: string;
    weight: string;
    style: string;
    url: string;
  }>;
};
export const weight2Name: Record<string, string> = {
  "100": "Thin",
  "200": "Extra Light",
  "300": "Light",
  "400": "Regular",
  "500": "Medium",
  "600": "Semi Bold",
  "700": "Bold",
  "800": "Extra Bold",
  "900": "Black",
  "950": "Extra Black",
};
