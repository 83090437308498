import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IProjectModel } from "../../stores/project/projectModel";
import { useMst } from "../../stores/Root";
import { IuploadDataModel } from "../../stores/uploadData";
import DemoPlayer from "./DemoPlayer";
import "./EditVideo.scss";
import EditView from "./EditView";
import SceneView from "./SceneView";
import { LayersAndModsPerScene } from "../../types/EditVideoTypes";
import { PATHS, PROJECT_PATHS, toPath } from "../../PATHS";
import { UseCaseTemplateEditor } from "../WelcomeProjectsGallery/UseCaseGallery/UseCaseTemplateEditor";
import { Button } from "antd";
import { FontInput } from "../../API";
import { font2Hash } from "../../utils/font";
import { AsyncOpState } from "../../types/enums/async-op-states";
import { BlingsBtn } from "../../components/antd-extensions/blings-btn.component";
import { getSnapshot, isStateTreeNode } from "mobx-state-tree";

type Props = {
  project: IProjectModel;
  uploadData: IuploadDataModel;
  scene: string;
  currentSearch: string;
  setCurrentSearch: (searchString: string) => void;
  setSearchView: (searchView: boolean) => void;
  isBranding: boolean;
  hasUnpublishedConnnectorChanges: boolean | undefined;
  fontOverrides: FontInput[];
  setFontOverrides?: (changes: FontInput[]) => void;
};

function safeGetSnapshot<T>(node: T): T {
  return isStateTreeNode(node) ? getSnapshot(node) : node;
}

function isOverlayAudioDifferent(overlayOne: any, overlayTwo: any) {
  const overlayOneAudio =
    overlayOne?.audio?.[0] && overlayOne.audio?.[0]?.url
      ? overlayOne.audio?.[0]
      : undefined;
  const overlayTwoAudio =
    overlayTwo?.audio?.[0] && overlayTwo.audio?.[0]?.url
      ? overlayTwo.audio?.[0]
      : undefined;

  if (!overlayOneAudio && !overlayTwoAudio) {
    return false;
  } else {
    const one = JSON.stringify(safeGetSnapshot(overlayOne ?? {}));
    const two = JSON.stringify(safeGetSnapshot(overlayTwo ?? {}));

    return one !== two;
  }
}

// the order of the fonts in the array is not important
export function isFontsAreDifferent(
  fontsOne: FontInput[],
  fontsTwo: FontInput[]
): boolean {
  if (fontsOne.length !== fontsTwo.length) {
    return true;
  }
  const mapFonts1 = fontsOne.reduce<Record<string, string>>((acc, font) => {
    acc[font2Hash(font)] = font.url;
    return acc;
  }, {});
  const mapFonts2 = fontsTwo.reduce<Record<string, string>>((acc, font) => {
    acc[font2Hash(font)] = font.url;
    return acc;
  }, {});
  const keys1 = Object.keys(mapFonts1);

  for (let key of keys1) {
    if (mapFonts2[key] === mapFonts1[key]) {
      delete mapFonts2[key];
      delete mapFonts1[key];
      continue;
    } else {
      return true;
    }
  }
  if (Object.keys(mapFonts2).length || Object.keys(mapFonts1).length) {
    return true;
  }
  return false;
}

const EditVideo = observer(
  ({
    project,
    uploadData,
    scene,
    setCurrentSearch,
    setSearchView,
    isBranding,
    hasUnpublishedConnnectorChanges,
    fontOverrides,
    setFontOverrides,
  }: Props) => {
    // local states for UI only
    const [allLayersAndMods, setAllLayersAndMods] = useState<
      LayersAndModsPerScene[]
    >([]);
    const [publishButtonState, setPublishButtonState] = useState<AsyncOpState>(
      AsyncOpState.Changed
    );
    const [activePanels, setActivePanels] = useState<string[]>([]);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const publishButtonTexts = {
      [AsyncOpState.Changed]: "Publish Changes",
      [AsyncOpState.Saving]: "Publishing",
      [AsyncOpState.Success]: "Published",
      [AsyncOpState.Error]: "Error",
    };

    const history = useNavigate();

    // Compute branding changes directly from the store values.
    const unsavedBrandingChanges =
      JSON.stringify(safeGetSnapshot(project.settingsDraftInEdit)) !==
        JSON.stringify(safeGetSnapshot(project.settingsDraft)) ||
      isOverlayAudioDifferent(
        project.overlayDraftInEdit,
        project.overlayDraft
      ) ||
      isFontsAreDifferent(
        project.fontOverridesDraftInEdit || [],
        project.fontOverridesDraft || []
      );

    const unpublishedBrandingChanges =
      JSON.stringify(safeGetSnapshot(project.settingsDraft)) !==
        JSON.stringify(safeGetSnapshot(project.settings)) ||
      isOverlayAudioDifferent(project.overlay, project.overlayDraft) ||
      isFontsAreDifferent(
        project.fontOverridesDraft || [],
        project.fontOverrides || []
      );

    // Update live control directly in the store.
    const handleLiveControlChange = (newLiveControl: any) => {
      const newSettings = { ...project.settingsDraftInEdit, ...newLiveControl };
      project.updateDraftLiveControlDataInEdit(newSettings);
    };

    const handleOverlayAudioChange = (
      newOverlayAudio:
        | { url: string; loop: boolean; volume: number }
        | ((prevState: { url: string; loop: boolean; volume: number }) => {
            url: string;
            loop: boolean;
            volume: number;
          })
    ) => {
      const audioState =
        typeof newOverlayAudio === "function"
          ? newOverlayAudio({
              url: project.overlayDraftInEdit?.audio?.[0]?.url || "",
              loop: project.overlayDraftInEdit?.audio?.[0]?.loop || true,
              volume: project.overlayDraftInEdit?.audio?.[0]?.volume || 1,
            })
          : newOverlayAudio;

      if (project.overlayDraftInEdit && project.overlayDraftInEdit.audio) {
        project.updateOverlayDraftDataInEdit({ audio: [audioState] });
      }
    };

    const handleSaveBrandingDraft = async () => {
      setIsSaving(true);
      try {
        await project.saveBrandingDraft();
      } catch (e) {
        console.error(e);
      }
      setIsSaving(false);
    };

    const handlePanelCollapse = (keys: string[] | string) => {
      if (!keys) setActivePanels([]);
      else {
        typeof keys === "string"
          ? activePanels.includes(keys)
            ? setActivePanels([])
            : setActivePanels([keys])
          : setActivePanels(keys);
      }
      // Get scene name from current panel
      let sceneName;
      try {
        //@ts-ignore
        sceneName = JSON.parse(keys)?.sceneName;
      } catch (e) {
        sceneName = "";
      }
      // Redirect to the scene from the active panel, if needed
      if (sceneName && sceneName !== scene) {
        history(
          toPath(
            PATHS.project,
            project.id,
            PROJECT_PATHS.editContent,
            sceneName
          )
        );
      }
    };

    const handlePublishChanges = async () => {
      try {
        setIsSaving(true);
        setPublishButtonState(AsyncOpState.Saving);
        await project.publishProject();
        setPublishButtonState(AsyncOpState.Changed);
      } catch (e) {
        console.error(e);
        setPublishButtonState(AsyncOpState.Error);
      }
      // Reset after 3 seconds
      setTimeout(() => {
        setPublishButtonState(AsyncOpState.Changed);
        setIsSaving(false);
      }, 3000);
    };

    const hasVideoParts = !!project.workspaceVideoParts.length;
    if (!hasVideoParts)
      return (
        <div
          className="edit-content-main"
          style={{ alignItems: "center", marginTop: "30vh" }}
        >
          Create a video using Blings extension, and see a demo here
        </div>
      );

    const uniqueFontsInVideoParts = Object.values(
      project.workspaceVideoParts.reduce<Record<string, FontInput>>(
        (acc, part) => {
          if (part.fonts) {
            part.fonts.forEach((font) => {
              acc[font2Hash(font)] = font;
            });
          }
          return acc;
        },
        {}
      )
    );

    return (
      <div className={"edit-content-main"}>
        <div className={"Container"}>
          <div className={"Scenes"}>
            <SceneView project={project} selectedScene={scene} />
          </div>
          {isBranding ? (
            <div className="edit-content-usecase-wrapper">
              <div className="wrapper">
                <div className="content-wrapper">
                  <span className="subtitle">
                    Modify the settings below to reflect your brand
                  </span>
                  <UseCaseTemplateEditor
                    overlayAudio={{
                      url: project.overlayDraftInEdit?.audio?.[0]?.url ?? "",
                      loop:
                        project.overlayDraftInEdit?.audio?.[0]?.loop ?? true,
                      volume:
                        project.overlayDraftInEdit?.audio?.[0]?.volume ?? 1,
                    }}
                    handleOverlayAudioChange={handleOverlayAudioChange}
                    accountId={project?.account?.id}
                    projectId={project.id}
                    setIsSavingTemplate={setIsSaving}
                    liveControl={project.settingsDraftInEdit}
                    handleLiveControlChange={handleLiveControlChange}
                    isUseCasePage={false}
                    fontsInVideoParts={uniqueFontsInVideoParts}
                    fontOverrides={project.fontOverridesDraftInEdit || []}
                    setFontOverrides={project.setFontOverridesDraftInEdit}
                  />
                  <div className="save-draft-btn-wrapper">
                    <Button
                      className="saving-draft-button"
                      disabled={!unsavedBrandingChanges || isSaving}
                      onClick={handleSaveBrandingDraft}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
              <div className="edit-content-usecase-buttons">
                <div>
                  {(hasUnpublishedConnnectorChanges ||
                    unpublishedBrandingChanges) &&
                    "Done editing?"}
                </div>
                <div id="PublishButton">
                  <BlingsBtn
                    className={"publish ButtonEditVideo"}
                    opState={publishButtonState}
                    htmlType={"submit"}
                    btnTexts={publishButtonTexts}
                    onClick={handlePublishChanges}
                    disabled={
                      !(
                        hasUnpublishedConnnectorChanges ||
                        unpublishedBrandingChanges
                      )
                    }
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className={"Edit"}>
              <EditView
                project={project}
                selectedScene={scene}
                handlePanelCollapse={handlePanelCollapse}
                activePanels={activePanels}
                setAllLayersAndMods={setAllLayersAndMods}
                allLayersAndMods={allLayersAndMods}
                setSearchView={setSearchView}
                setCurrentSearch={setCurrentSearch}
                // Now using the computed flag rather than local state:
                hasUnpublishedBrandingChanges={unpublishedBrandingChanges}
              />
            </div>
          )}
          <div className={"Demo"}>
            <DemoPlayer
              scene={scene}
              project={project}
              uploadData={uploadData}
            />
          </div>
        </div>
      </div>
    );
  }
);

// The wrapper now reads values directly from the store and passes them to EditVideo.
const EditVideoWrapper = observer(() => {
  const { id, scene } = useParams<{ id: string; scene: string }>();

  const {
    setSelectedProjectById,
    project,
    setSearchView,
    currentSearch,
    setCurrentSearch,
    hasUnpublishedConnnectorChanges,
    uploadData,
    searchView,
    fontOverrides,
    setFontOverrides,
  } = useMst((store) => ({
    setSelectedProjectById: store.projectsStore.setSelectedProjectById,
    project: store.projectsStore.selectedProject,
    setSearchView: store.editVideoStore.setSearchView,
    currentSearch: store.editVideoStore.currentSearch,
    setCurrentSearch: store.editVideoStore.setCurrentSearch,
    hasUnpublishedConnnectorChanges:
      store.projectsStore.selectedProject?.hasUnpublishedConnnectorChanges,
    // uploadData: store.uploadData,
    searchView: store.editVideoStore.searchView,
    fontOverrides:
      store.projectsStore?.selectedProject?.fontOverridesDraftInEdit,
    setFontOverrides:
      store.projectsStore?.selectedProject?.setFontOverridesDraftInEdit,
  }));

  useEffect(() => {
    if (id) {
      setSelectedProjectById(id);
    }
    if (scene === "_branding" || (!scene && !searchView)) {
      setSearchView(true);
      setCurrentSearch("");
    }
  }, [
    id,
    scene,
    searchView,
    setSelectedProjectById,
    setSearchView,
    setCurrentSearch,
  ]);

  if (!project) {
    return null;
  }

  const isBranding = scene === "_branding";
  const computedScene = isBranding ? "" : scene;

  return (
    <EditVideo
      project={project}
      uploadData={uploadData}
      scene={computedScene || ""}
      currentSearch={currentSearch}
      setCurrentSearch={setCurrentSearch}
      setSearchView={setSearchView}
      isBranding={isBranding}
      hasUnpublishedConnnectorChanges={hasUnpublishedConnnectorChanges}
      fontOverrides={fontOverrides}
      setFontOverrides={setFontOverrides}
    />
  );
});

export default EditVideoWrapper;
