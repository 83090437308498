import { StaticTemplateProps } from "./StaticTemplate";
import { CREATE_PROJECT_MP5_GEN_BASE_URL } from "../../../consts";

export const ttvStaticContent: StaticTemplateProps = {
  props: {
    image: "AI",
    title: "Wireframe with AI",
    titleDetail: "Beta",
    content: [
      {
        subtitle: "Describe Your Vision",
        text: "Start by simply describing your concept. Whether it’s a product demo, a customer update, or a personalized message, type it out and let our TTV tool do the heavy lifting.",
      },
      {
        subtitle: "Watch It Transform",
        text: "Our early version of TTV translates your text into a dynamic, visually engaging video. Every element is designed to keep your audience hooked, from start to finish.",
      },
      {
        subtitle: "Personalize the Experience",
        text: "Enhance the video with customer data, making every interaction feel tailored and meaningful.",
      },
    ],
    footerTitle: "Ready to turn your thoughts into action?",
    buttonText: "Let’s begin",
    redirectPage: CREATE_PROJECT_MP5_GEN_BASE_URL,
  },
};

export const aeStaticContent: StaticTemplateProps = {
  props: {
    image: "AE",
    title: "Build with Full flexibility",
    content: [
      {
        subtitle: "Start in Adobe After Effects (AE):",
        text: "Leverage your creative team’s expertise by designing a visually stunning video. This step allows full control over branding, animations, and storytelling.",
      },
      {
        subtitle: "Export with the Blings Extension",
        text: "Using the Blings AE extension, your team can export the video directly into Blings. Each layer is automatically converted into a JSON element, making it fully customizable and ready for data integration.",
      },
      {
        subtitle: "Personalize and Connect",
        text: "Blings empowers you to seamlessly connect customer data with video elements. Add personalized touches such as names, purchase histories, or tailored calls-to-action that create meaningful, one-to-one engagement",
      },
    ],
    footerTitle: "Low on design bandwidth?",
    footerSubText: {
      text: "Ask your account manager about our",
      textBold: "Motion Design services",
    },
  },
};
