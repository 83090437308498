import React, { useState, useEffect, useRef } from "react";
import {
  ISdkParams,
  ISdkSettings,
} from "@blings/blings-player/lib/src/SDK/sdk.api";
import classNames from "classnames";
import PlayerManager from "../utils/playerManager";
import { Player } from "@blings/blings-player";
import useDebounce from "../helpers/DebounceHook";

export type SdkPlayerProps = {
  sdkParams: Omit<ISdkParams, "settings"> & {
    settings: Omit<ISdkSettings, "container"> & { container?: HTMLElement };
  };
  style?: React.CSSProperties;
  className?: string;
  ref?: React.Ref<HTMLDivElement>;
};

export default function SdkPlayer(props: SdkPlayerProps) {
  const { sdkParams, style, className, ref } = props;
  const debouncedSdkParams = useDebounce(sdkParams, 500);
  const containerRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<Player | null>(null);
  const [isLoading, setIsLoding] = useState<boolean>(false);
  useEffect(() => {
    if (!containerRef.current) return;
    setIsLoding(true);
    const destroyCurrentPlayer = async () => {
      if (playerRef.current) {
        await playerRef.current.destroy();
        playerRef.current = null;
      }
    };
    const createNewPlayer = async () => {
      await destroyCurrentPlayer();
      if (!containerRef.current) return;
      const fullSdkParams: ISdkParams = {
        ...debouncedSdkParams,
        settings: {
          ...debouncedSdkParams.settings,
          container: containerRef.current,
        },
      };
      playerRef.current = await PlayerManager.get().createPlayer(
        fullSdkParams,
        {
          playerMajorVersion: 4,
          newPlayer: true,
        }
      );
      setIsLoding(false);
    };
    createNewPlayer();
    return () => {
      setIsLoding(false);
      destroyCurrentPlayer();
    };
  }, [debouncedSdkParams]);

  return (
    <div
      className={classNames("vid-wrapper", className)}
      style={style}
      ref={ref}
    >
      {isLoading ? (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <h1
            style={{
              font: "Inter",
              fontSize: "16px",
              fontWeight: "500",
              color: "#1b2b4b",
            }}
          >
            Loading...
          </h1>
        </div>
      ) : null}

      <div className="vid-container" ref={containerRef}></div>
    </div>
  );
}
