import { observer } from "mobx-react-lite";
import { MSTContext, useMst } from "../../../stores/Root";
import { useContext, useEffect, useState } from "react";
import { Typography } from "antd";
import { ExperimentOptimizationTechnique } from "../../../API";
import ExperimentPanel from "../../../components/AI/Optimization/VariantPanel/ExperimentPanel";
import EnableOptimizationPanel from "../../../components/AI/Optimization/OptimizationPanel/EnableOptimizationPanel";
import "./AIOptimization.scss";
import OptimizationPanel from "../../../components/AI/Optimization/OptimizationPanel/OptimizationPanel";
import { StarsIcon } from "../../../assets/Icons";
import { BlingsCard } from "../../../components/BlingsCard/BlingsCard";

type Props = {
  projectId: string;
};

const AIOptimization = observer(function (props: Props) {
  const store = useContext(MSTContext);
  const { loadStatus } = store.userStore;
  const selectedExperimentOptimizationTechnique =
    store.projectsStore.selectedProject?.experiments
      ?.selectedExperimentOptimizationTechnique;
  const [accountCanAccess, setAccountCanAccess] = useState(false);
  const [_selectedSuccessFactor, setSelectedSuccessFactor] = useState(
    selectedExperimentOptimizationTechnique
  );
  const [
    shouldShowOptimizationSelectionPanel,
    setShouldShowOptimizationSelectionPanel,
  ] = useState(false);

  // useEffect declarations
  useEffect(() => {
    setSelectedSuccessFactor(
      selectedExperimentOptimizationTechnique ||
        ExperimentOptimizationTechnique.DISABLED
    );
  }, [selectedExperimentOptimizationTechnique]);

  useEffect(() => {
    const accountCan = store.userStore.accountFromId(
      store.projectsStore.selectedProject?.account?.id
    )?.accountCan;
    if (accountCan) setAccountCanAccess(accountCan("aiOptimization"));
  }, [loadStatus]);

  return (
    <div className="ai-page">
      {!accountCanAccess ? (
        <div className="ai-optimization-not-allowed">
          <StarsIcon />
          <div className="ai-optimization-not-allowed-content">
            <Typography.Text>AI is disabled for this account</Typography.Text>
            <Typography.Text>
              Contact sales for more information
            </Typography.Text>
          </div>
        </div>
      ) : (
        <>
          {_selectedSuccessFactor !==
            ExperimentOptimizationTechnique.DISABLED &&
          !shouldShowOptimizationSelectionPanel ? (
            <BlingsCard className="ai-optimization-optimization-selection-card">
              <OptimizationPanel
                setShouldShowOptimizationSelectionPanel={
                  setShouldShowOptimizationSelectionPanel
                }
              />
            </BlingsCard>
          ) : null}
          <BlingsCard className="ai-optimization-container">
            {_selectedSuccessFactor !==
              ExperimentOptimizationTechnique.DISABLED &&
            !shouldShowOptimizationSelectionPanel ? (
              <ExperimentPanel />
            ) : (
              <EnableOptimizationPanel
                setShouldShowOptimizationSelectionPanel={
                  setShouldShowOptimizationSelectionPanel
                }
              />
            )}
          </BlingsCard>
        </>
      )}
    </div>
  );
});

export default AIOptimization;
