import React, { useContext, useEffect, useState } from "react";
import {
  Radio,
  RadioChangeEvent,
  Dropdown,
  Menu,
  Popconfirm,
  Input,
  Tooltip,
} from "antd";
import { BlingsCard } from "../BlingsCard/BlingsCard";
import { BlingsDynamicForm } from "./BlingsDynamicForm";
import { OverviewPageContext } from "../../view/ProjectDemoPage/OverviewPage";
import _ from "lodash";
import { useMst } from "../../stores/Root";
import {
  SpinnerIcon,
  KebabMenu,
  PlusIcon,
  EditIconDynamicData,
  RenameIcon,
  DuplicateIcon,
  DeleteIconDynamicData,
} from "../../assets/Icons";
import "./BlingsCardDynamicData.scss";

interface Props {
  setIsModalOpen: React.Dispatch<
    React.SetStateAction<{ modalOpen: boolean; mode: "create" | "edit" }>
  >;

  readonly?: boolean;
  children?: React.ReactNode;
  dataPointSelectorProps: {
    selectedDataPointId: string | undefined;
    setSelectedDataPointId: React.Dispatch<
      React.SetStateAction<string | undefined>
    >;
    fillDataFromRecord: (dataId: string | undefined) => void;
  };
  dynamicFormProps: {
    schema: object;
    currentFormData: object;
    setLastFormChange: (formData: object) => void;
    setCurrentFormData: (formData: object) => void;
    setFormHasChanged: (hasChanged: boolean) => void;
    setChangedFromRecordData: (hasChanged: boolean) => void;
    submit: React.MutableRefObject<() => void>;
  };
  getData: any;
}

export default function BlingsCardDynamicData({
  setIsModalOpen,
  readonly,
  children,
  dynamicFormProps,
  dataPointSelectorProps,
  getData,
}: Props) {
  const { project, dataPointsFromCSV, dataPointsFromManual } =
    useContext(OverviewPageContext);

  const [menuVisible, setMenuVisible] = useState<Record<string, boolean>>({});
  const deleteDatapoint = useMst((root) => root.projectsStore.deleteDataPoint);
  const updateDataPointName = useMst(
    (root) => root.projectsStore.updateDataPointName
  );

  const [renameKey, setRenameKey] = useState<string | null>(null);
  const [renameValue, setRenameValue] = useState<string>("");

  const toggleMenu = (key: string) => {
    setMenuVisible((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const closeMenu = (key: string) => {
    setMenuVisible((prev) => ({ ...prev, [key]: false }));
  };

  const handleRename = async (currentKey: string) => {
    setRenameKey(currentKey);
    setRenameValue(currentKey);
  };

  const handleRenameConfirm = async () => {
    if (!renameKey) return;

    const oldName = renameKey;
    const newName = renameValue.trim();

    if (newName === oldName) {
      setRenameKey(null);
      setRenameValue("");
      return;
    }

    // Check if the new name already exists on dataPointsFromManual ou dataPointsFromCSV
    const manualKeys = dataPointsFromManual
      ? Object.keys(dataPointsFromManual)
      : [];
    const csvKeys = dataPointsFromCSV ? Object.keys(dataPointsFromCSV) : [];
    const allKeys = [...manualKeys, ...csvKeys];

    if (allKeys.includes(newName) || newName === "Default Version") {
      return;
    }

    await updateDataPointName(oldName, newName).then(() => getData());

    setRenameKey(null);
    setRenameValue("");
  };
  const menuItems = (currentKey: string) => (
    <Menu className="ant-dropdown-menu-item">
      <Menu.Item
        key="edit"
        onClick={() => setIsModalOpen({ modalOpen: true, mode: "edit" })}
      >
        <div className="dropdown-items">
          <EditIconDynamicData />
          <span>Edit</span>
        </div>
      </Menu.Item>

      <Menu.Item
        key="duplicate"
        onClick={() => {
          dataPointSelectorProps.setSelectedDataPointId(undefined);
          dataPointSelectorProps.fillDataFromRecord(target);
          setIsModalOpen({ modalOpen: true, mode: "create" });
        }}
      >
        <div className="dropdown-items">
          <DuplicateIcon />
          <span>Duplicate</span>
        </div>
      </Menu.Item>

      <Menu.Item
        key="rename"
        onClick={() => {
          handleRename(currentKey).then(() => getData());
        }}
      >
        <div className="dropdown-items">
          <RenameIcon />
          <span>Rename</span>
        </div>
      </Menu.Item>

      <Menu.Item key="delete">
        <Popconfirm
          icon={null}
          title="Delete this version?"
          onConfirm={() => {
            deleteDatapoint(currentKey).then(() => getData());
          }}
          okText="Delete"
          cancelText="Cancel"
          placement="topLeft"
          overlayInnerStyle={{ position: "relative", top: -130, right: 10 }}
          cancelButtonProps={{
            className: "custom-cancel-button",
          }}
          okButtonProps={{
            className: "custom-ok-button",
            style: {
              border: "1px solid #CDD5E5",
              color: "#7F8899",
              backgroundColor: "#FFFFFF",
            },
          }}
        >
          <div>
            <DeleteIconDynamicData />
            <span>Delete</span>
          </div>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  const [showLoading, setShowLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const [target, setTarget] = useState("");
  const handleTarget = (text: string) => {
    setTarget(text);
  };

  return (
    <BlingsCard
      title="Dynamic Data"
      className="blings-dynamic-data-card"
      withoutSeparator={true}
    >
      {!dataPointsFromCSV && !dataPointsFromManual && (
        <div className="blings-dynamic-data-preview-container">
          <div className="blings-dynamic-data-preview-selector">
            <div>
              <Radio.Group
                value={dataPointSelectorProps.selectedDataPointId || undefined}
                defaultValue={
                  dataPointSelectorProps.selectedDataPointId || undefined
                }
                onChange={(e: RadioChangeEvent) => {
                  dataPointSelectorProps.setSelectedDataPointId(
                    e.target.value || undefined
                  );
                  dataPointSelectorProps.fillDataFromRecord(
                    e.target.value || undefined
                  );
                }}
                optionType="button"
                buttonStyle="solid"
                className="blings-dynamic-data-preview-radio"
              >
                <Radio.Button
                  value={undefined}
                  className={
                    "blings-dynamic-data" +
                    (dataPointSelectorProps.selectedDataPointId === undefined
                      ? " selected"
                      : "")
                  }
                >
                  <div
                    className="data-content"
                    onDoubleClick={() => {
                      handleTarget("Default Version");
                      dataPointSelectorProps.setSelectedDataPointId(undefined);
                      dataPointSelectorProps.fillDataFromRecord(undefined);
                      setIsModalOpen({ modalOpen: true, mode: "edit" });
                    }}
                  >
                    <p>Default Version</p>
                  </div>
                </Radio.Button>
              </Radio.Group>

              {showLoading && (
                <div className="loading-data">
                  <SpinnerIcon color="#101010" />
                  <p>Checking for Data Versions...</p>
                </div>
              )}
            </div>
            {readonly ? (
              <div className="div-button-create-new-version">
                <button
                  className="button-create-new-version"
                  onClick={() => {
                    dataPointSelectorProps.setSelectedDataPointId(undefined);
                    dataPointSelectorProps.fillDataFromRecord(undefined);
                    setIsModalOpen({ modalOpen: true, mode: "edit" });
                  }}
                >
                  <PlusIcon />
                  <span>Create your own version</span>
                </button>
              </div>
            ) : (
              children
            )}
          </div>

          <BlingsDynamicForm
            dynamicFormProps={dynamicFormProps}
            readOnly={readonly}
            setIsModalOpen={setIsModalOpen}
            dataPointSelectorProps={dataPointSelectorProps}
            target={target}
          />
        </div>
      )}

      {(dataPointsFromCSV || dataPointsFromManual) && (
        <div className="blings-dynamic-data-preview-container">
          <div className="blings-dynamic-data-preview-selector">
            <div>
              <div>
                <Radio.Group
                  value={
                    dataPointSelectorProps.selectedDataPointId || undefined
                  }
                  defaultValue={
                    dataPointSelectorProps.selectedDataPointId || undefined
                  }
                  onChange={(e: RadioChangeEvent) => {
                    handleTarget(e.target.value);
                    dataPointSelectorProps.setSelectedDataPointId(
                      e.target.value || undefined
                    );
                    dataPointSelectorProps.fillDataFromRecord(
                      e.target.value || undefined
                    );
                  }}
                  optionType="button"
                  buttonStyle="solid"
                  className="blings-dynamic-data-preview-radio"
                >
                  <Radio.Button
                    value={undefined}
                    className={
                      "blings-dynamic-data" +
                      (dataPointSelectorProps.selectedDataPointId === undefined
                        ? " selected"
                        : "")
                    }
                  >
                    <div
                      className="data-content"
                      onDoubleClick={() => {
                        handleTarget("Default Version");
                        dataPointSelectorProps.setSelectedDataPointId(
                          undefined
                        );
                        dataPointSelectorProps.fillDataFromRecord(undefined);
                        setIsModalOpen({ modalOpen: true, mode: "edit" });
                      }}
                    >
                      <p>Default Version</p>
                    </div>
                  </Radio.Button>

                  {dataPointsFromManual
                    ? Object.keys(dataPointsFromManual).map((k, i) => (
                        <Radio.Button
                          value={k}
                          key={k}
                          className={
                            "blings-dynamic-data" +
                            (dataPointSelectorProps.selectedDataPointId === k
                              ? " selected"
                              : "")
                          }
                        >
                          <div
                            className="data-content"
                            onDoubleClick={() => {
                              setIsModalOpen({
                                modalOpen: true,
                                mode: "edit",
                              });
                            }}
                          >
                            {renameKey === k ? (
                              <Input
                                value={renameValue}
                                onChange={(e) => setRenameValue(e.target.value)}
                                onPressEnter={handleRenameConfirm}
                                onBlur={handleRenameConfirm}
                                autoFocus
                              />
                            ) : (
                              <Tooltip title={k}>
                                <p className="data-point-name">{k}</p>
                              </Tooltip>
                            )}

                            {dataPointSelectorProps.selectedDataPointId ===
                            k ? (
                              <Dropdown
                                overlay={menuItems(k)}
                                trigger={["click"]}
                                open={menuVisible[k]}
                                onOpenChange={(visible) => {
                                  if (!visible) closeMenu(k);
                                }}
                              >
                                <span
                                  style={{ paddingTop: "5px" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    toggleMenu(k);
                                  }}
                                >
                                  <KebabMenu />
                                </span>
                              </Dropdown>
                            ) : null}
                          </div>
                        </Radio.Button>
                      ))
                    : null}
                  {dataPointsFromCSV
                    ? Object.keys(dataPointsFromCSV)
                        .sort((a, b) => a.localeCompare(b))
                        .map((k) => (
                          <Radio.Button
                            value={k}
                            key={k}
                            className={
                              "blings-dynamic-data" +
                              (dataPointSelectorProps.selectedDataPointId === k
                                ? " selected"
                                : "")
                            }
                          >
                            <div
                              className="data-content"
                              onDoubleClick={() => {
                                setIsModalOpen({
                                  modalOpen: true,
                                  mode: "edit",
                                });
                              }}
                            >
                              {renameKey === k ? (
                                <Input
                                  onClick={() => handleTarget(k)}
                                  value={renameValue}
                                  onChange={(e) =>
                                    setRenameValue(e.target.value)
                                  }
                                  onPressEnter={handleRenameConfirm}
                                  onBlur={handleRenameConfirm}
                                  autoFocus
                                />
                              ) : (
                                <p>{k}</p>
                              )}

                              {dataPointSelectorProps.selectedDataPointId ===
                              k ? (
                                <Dropdown
                                  overlay={menuItems(k)}
                                  trigger={["click"]}
                                  open={menuVisible[k]}
                                  onOpenChange={(visible) => {
                                    if (!visible) closeMenu(k);
                                  }}
                                >
                                  <span
                                    style={{ paddingTop: "5px" }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      toggleMenu(k);
                                    }}
                                  >
                                    <KebabMenu />
                                  </span>
                                </Dropdown>
                              ) : null}
                            </div>
                          </Radio.Button>
                        ))
                    : null}
                </Radio.Group>
              </div>
            </div>
            {readonly ? (
              <div className="div-button-create-new-version">
                <button
                  className="button-create-new-version"
                  onClick={() => {
                    dataPointSelectorProps.setSelectedDataPointId(undefined);
                    dataPointSelectorProps.fillDataFromRecord(undefined);
                    setIsModalOpen({ modalOpen: true, mode: "edit" });
                  }}
                >
                  <PlusIcon />
                  <span>Create your own version</span>
                </button>
              </div>
            ) : (
              children
            )}
          </div>
          <BlingsDynamicForm
            dynamicFormProps={dynamicFormProps}
            readOnly={readonly}
            setIsModalOpen={setIsModalOpen}
            dataPointSelectorProps={dataPointSelectorProps}
            target={target}
          />
        </div>
      )}
    </BlingsCard>
  );
}
