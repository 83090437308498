import { IJSONObject } from "@blings/blings-player";

export interface ExtractedValues {
  images: string[];
  colors: string[];
  texts: string[];
  numbers: string[];
}

export const getTypesFromLiveControl = (
  liveControl: IJSONObject
): ExtractedValues => {
  if (!liveControl) {
    return { images: [], colors: [], texts: [], numbers: [] };
  }
  const isValidLink = (value: string): boolean => {
    const urlPattern = new RegExp(
      "(https?://.*.(?:png|jpe?g|gif|bmp|webp)(?:.*)?)$",
      "i"
    );
    return urlPattern.test(value);
  };

  const isValidHexColor = (value: string): boolean =>
    /^#([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})$/.test(value);

  const values = liveControl || {};

  const result: ExtractedValues = Object.keys(values).reduce(
    (acc: ExtractedValues, key: string) => {
      const value = values[key];
      if (typeof value === "string") {
        if (isValidLink(value)) {
          acc.images.push(key);
        } else if (isValidHexColor(value)) {
          acc.colors.push(key);
        } else if (typeof value === "number") {
          acc.numbers.push(key);
        } else {
          acc.texts.push(key);
        }
      }
      return acc;
    },
    { images: [], colors: [], texts: [], numbers: [] }
  );

  return result;
};
