import { AsyncOpState } from "../../types/enums/async-op-states";
import {
  FontInput,
  OverlayInput,
  UpdateProjectInput,
  UpdateProjectMutation,
  UpdateProjectVersionInput,
  UpdateProjectVersionMutation,
} from "../../API";
import { updateProject, updateProjectVersion } from "../../graphql/mutations";
import { INITIAL_ASYNC_OP_STATE } from "../../types/mst/async-op-state";
import { IProjectBaseModel } from "./projectModel";
import { generateClient } from "aws-amplify/api";

const client = generateClient();

// type x = Pick<IProjectModel, 'model'>

function removeFontTypeToFontOverrides(fontOverrides: any) {
  return fontOverrides.map((font: any) => {
    const newFont = { ...font };
    newFont?.__typename && delete newFont.__typename;
    return newFont;
  });
}

export const saveSchemasActions = (self: IProjectBaseModel) => ({
  updateLiveControlData(newSettings: any) {
    self.settings = newSettings;
  },
  updateDraftLiveControlData(newSettings: any) {
    self.settingsDraft = JSON.parse(JSON.stringify(newSettings));
  },
  updateDraftLiveControlDataInEdit(newSettings: any) {
    self.settingsDraftInEdit = JSON.parse(JSON.stringify(newSettings));
  },
  updateFontOverridesDraftDataInEdit(newFontOverrides: FontInput[]) {
    const cleanedFonts = removeFontTypeToFontOverrides(newFontOverrides);
    self.fontOverridesDraftInEdit = cleanedFonts;
  },
  updateFontOverridesDraftData(newFontOverrides: FontInput[]) {
    self.fontOverridesDraft = JSON.parse(JSON.stringify(newFontOverrides));
  },

  updateOverlayDraftData(newOverlay: OverlayInput) {
    if (!newOverlay.audio || newOverlay.audio.length === 0) {
      self.overlayDraft = {
        audio: [],
      };
      return;
    }
    self.overlayDraft = {
      audio: [
        {
          url: newOverlay.audio?.[0].url || "",
          volume: newOverlay.audio?.[0].volume ?? 1,
          loop: newOverlay.audio?.[0].loop ?? true,
        },
      ],
    };
  },

  updateOverlayDraftDataInEdit(newOverlay: OverlayInput) {
    self.overlayDraftInEdit = {
      audio: [
        {
          url: newOverlay.audio?.[0].url || "",
          volume: newOverlay.audio?.[0].volume ?? 1,
          loop: newOverlay.audio?.[0].loop ?? true,
        },
      ],
    };
  },

  async saveBrandingDraft() {
    const fonts = self.fontOverridesDraftInEdit
      ? removeFontTypeToFontOverrides(self.fontOverridesDraftInEdit)
      : [];
    const input: UpdateProjectVersionInput = {
      id: self.id,
      fontOverrides: fonts,
      settings: JSON.stringify(self.settingsDraftInEdit),
      overlay: JSON.parse(JSON.stringify(self.overlayDraftInEdit)),
    };
    try {
      const resp = (await client.graphql({
        query: updateProjectVersion,
        variables: { input },
      })) as { data: UpdateProjectVersionMutation };
      if (resp.data.updateProjectVersion) {
        this.updateDraftLiveControlData(self.settingsDraftInEdit);
        this.updateOverlayDraftData(self.overlayDraftInEdit as OverlayInput);
        this.updateFontOverridesDraftData(fonts as FontInput[]);
      } else {
        throw new Error("");
      }
    } catch (e) {
      console.log(e);
    }
  },
  async publishBranding() {
    const input: UpdateProjectInput = {
      id: self.id,
      fontOverrides: self.fontOverridesDraft,
      settings: JSON.stringify(self.settingsDraft),
      overlay: JSON.parse(JSON.stringify(self.overlayDraft)),
    };
    try {
      const resp = (await client.graphql({
        query: updateProject,
        variables: { input },
      })) as { data: UpdateProjectMutation };
      if (resp.data.updateProject) {
        self.settings = JSON.parse(JSON.stringify(self.settingsDraft));
        self.fontOverrides = JSON.parse(
          JSON.stringify(self.fontOverridesDraft)
        );
        self.overlay = JSON.parse(JSON.stringify(self.overlayDraft));
      } else {
        throw new Error("");
      }
    } catch (e) {
      console.log(e);
    }
  },

  updateLiveControlSchema(newSchema: any) {
    self.settingsJsonSchemaStr = JSON.stringify(newSchema);
  },
  updatePerVideoSchema(newSchema: any) {
    self.stateJsonSchemaStr = JSON.stringify(newSchema);
  },
  async savePerVideoData(newSchema: any) {
    const beforeSchema = self.stateJsonSchemaStr;
    this.updatePerVideoSchema(newSchema);
    this.changeSavePerVideoDataStatus(AsyncOpState.Saving);
    try {
      const input = {
        id: self.id,
        stateJsonSchemaStr: JSON.stringify(newSchema),
      };
      const resp = (await client.graphql({
        query: updateProject,
        variables: { input },
      })) as { data: UpdateProjectMutation };
      const respVersion = (await client.graphql({
        query: `
        mutation UpdateProjectVersion($input: UpdateProjectVersionInput!) {
          updateProjectVersion(input: $input) {
            id
            stateJsonSchemaStr
            accountOwner
            createdAt
          }
        }
      `,
        variables: { input },
      })) as { data: UpdateProjectVersionMutation };
      if (resp.data.updateProject && respVersion.data) {
        this.savePerVideoDataSuccess();
      } else {
        throw new Error("");
      }
    } catch (e) {
      this.changeSavePerVideoDataStatus(AsyncOpState.Error);
      setTimeout(() => {
        this.changeSavePerVideoDataStatus(INITIAL_ASYNC_OP_STATE);
        this.updatePerVideoSchema(JSON.stringify(beforeSchema));
      }, 3000);
    }
  },
  async saveLiveControl(newData: any, newSchema: any) {
    const beforeSettings = JSON.stringify(self.settings);
    const beforeSchema = self.settingsJsonSchemaStr;
    this.updateLiveControlData(newData);
    if (newSchema) this.updateLiveControlSchema(newSchema);
    this.changeSaveLiveControlStatus(AsyncOpState.Saving);
    try {
      const input = {
        id: self.id,
        settings: JSON.stringify(newData),
        ...(newSchema && { settingsJsonSchemaStr: JSON.stringify(newSchema) }),
      };
      const resp = (await client.graphql({
        query: updateProject,
        variables: { input },
      })) as { data: UpdateProjectMutation };
      const respVersion = (await client.graphql({
        query: `
        mutation UpdateProjectVersion($input: UpdateProjectVersionInput!) {
          updateProjectVersion(input: $input) {
            id
            settingsJsonSchemaStr
            settings
            accountOwner
            createdAt
          }
        }
      `,
        variables: { input },
      })) as { data: UpdateProjectVersionMutation };
      if (resp.data.updateProject && respVersion.data) {
        this.saveLiveControlSuccess();
      } else {
        throw new Error("");
      }
    } catch (e) {
      this.changeSaveLiveControlStatus(AsyncOpState.Error);
      setTimeout(() => {
        this.changeSaveLiveControlStatus(INITIAL_ASYNC_OP_STATE);
        this.updateLiveControlData(JSON.parse(beforeSettings));
        this.updateLiveControlSchema(JSON.stringify(beforeSchema));
      }, 3000);
    }
  },
  saveLiveControlSuccess() {
    this.changeSaveLiveControlStatus(AsyncOpState.Success);
    setTimeout(() => {
      this.changeSaveLiveControlStatus(INITIAL_ASYNC_OP_STATE);
    }, 3000);
  },
  savePerVideoDataSuccess() {
    this.changeSavePerVideoDataStatus(AsyncOpState.Success);
    setTimeout(() => {
      this.changeSavePerVideoDataStatus(INITIAL_ASYNC_OP_STATE);
    }, 3000);
  },
  changeSaveLiveControlStatus(newStatus: AsyncOpState) {
    self.saveLiveControlStatus = newStatus;
  },
  changeSavePerVideoDataStatus(newStatus: AsyncOpState) {
    self.savePerVideoDataStatus = newStatus;
  },
});
