import { observer } from "mobx-react-lite";
import { Space } from "antd";
import { useContext, useRef, useState } from "react";
import { DynamicForm } from "@blings/dynamic-form";
import { IProjectModel } from "../../stores/project/projectModel";
import { BlingsBtn } from "../../components/antd-extensions/blings-btn.component";
import { AsyncOpState } from "../../types/enums/async-op-states";
import { MSTContext } from "../../stores/Root";
import BlingsPlatformTheme from "../../config/antdTheme";
import { UploadFile } from "antd/lib";

type Props = {
  project: IProjectModel;
};

function ProjectLiveControl({ project }: Props) {
  const schema = JSON.parse(project.settingsJsonSchemaStr || "{}");
  const settings = project.settings || {};
  const submit = useRef<() => void>(() => {
    return;
  });
  const [currentOpenField, setCurrentOpenField] = useState("");
  const {
    liveControlStore: { getAssetsUploadStatus, uploadAssetToProject },
  } = useContext(MSTContext);

  return (
    <div className={"ProjectLiveControl project-tab-padding"}>
      <Space direction="vertical" style={{ width: "60%" }}>
        <DynamicForm
          themeConfig={BlingsPlatformTheme}
          schema={schema}
          formData={settings}
          editable={false}
          saveData={project.saveLiveControl}
          submit={submit}
          setCurrentOpenField={setCurrentOpenField}
          getAssetsUploadStatus={getAssetsUploadStatus}
          uploadAssetToProject={async (
            file: File | UploadFile<any>,
            s3Folder: string,
            cb?: (key: string) => void
          ) => {
            uploadAssetToProject(file, { s3Folder }, cb);
          }}
        />
        {currentOpenField ? (
          <BlingsBtn
            opState={project.saveLiveControlStatus}
            htmlType={"submit"}
            btnTexts={{
              [AsyncOpState.Error]: "Error Saving Data",
              [AsyncOpState.Saving]: "Saving",
              [AsyncOpState.Untouched]: "Save",
              [AsyncOpState.Changed]: "Save",
              [AsyncOpState.Success]: "DONE",
            }}
            onClick={() => submit.current()}
          />
        ) : null}
      </Space>
    </div>
  );
}

export default observer(ProjectLiveControl);
