import { Button, Modal } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";

import CardsGallery from "../../../components/CardsGallery/CardsGallery";
import { MSTContext, useMst } from "../../../stores/Root";
import "./UseCaseGallery.scss";
import { useNavigate } from "react-router-dom";
import { toPath } from "../../../PATHS";
import UseCaseTemplate from "./UseCaseTemplate";
import { UseCase, UseCaseTag } from "../../../API";
import { observer } from "mobx-react-lite";
import { CloseXIcon } from "../../../icons-and-animations/icons";
import { LeftOutlinedIcon } from "../../../icons-and-animations/icons";
import LottieLoader from "../../../components/LottieLoader";

const UseCaseGallery = observer(() => {
  const history = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedUseCase, setSelectedUseCase] = useState<{
    useCase: UseCase;
    tags: Array<UseCaseTag>;
  } | null>(null);

  const store = useContext(MSTContext);
  const { loadProjectInfoIntoProjectsArray } = store.projectsStore;

  const { tags, useCases, instantiateUseCase, isUseCasesLoading } =
    store.useCasesStore;
  const { mainAccount, accounts } = store.userStore;
  const accountId = mainAccount?.id;

  const handleSelectUseCase = useCallback(
    (useCase: UseCase) => {
      const filteredTags = tags?.filter((tag: UseCaseTag) =>
        tag.useCaseIds.includes(useCase.id)
      );
      setSelectedUseCase({ useCase, tags: filteredTags || [] });
      setIsModalVisible(true);
    },
    [tags]
  );

  useEffect(() => {
    if (!isModalVisible) {
      setSelectedUseCase(null);
    }
  }, [isModalVisible]);

  return (
    <div className="use-case-gallery">
      <div className="use-case-gallery-header">
        <Button
          type="text"
          className="back-btn"
          onClick={() => history(toPath("welcome"))}
        >
          <LeftOutlinedIcon />
          Back
        </Button>
      </div>
      {isUseCasesLoading() ? (
        <LottieLoader />
      ) : (
        <div className="use-case-gallery-cards">
          <CardsGallery
            cardType="usecase"
            items={useCases || []}
            title="Explore Use Cases"
            searchPlaceholder="Search Use Cases"
            handleClick={handleSelectUseCase}
            getItemName="name"
          />
        </div>
      )}
      {selectedUseCase ? (
        <Modal
          closeIcon={<CloseXIcon />}
          width={766}
          style={{ borderRadius: 20, overflow: "auto" }}
          className="use-case-modal"
          centered
          open={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
          closable={true}
          destroyOnClose={true}
          maskClosable={false}
        >
          <UseCaseTemplate
            usecase={selectedUseCase?.useCase || null}
            tags={selectedUseCase?.tags}
            accountId={accountId || ""}
            accounts={accounts || []}
            instantiateUseCase={instantiateUseCase}
            loadProjectInfoIntoProjectsArray={loadProjectInfoIntoProjectsArray}
          />
        </Modal>
      ) : null}
    </div>
  );
});

export default UseCaseGallery;
