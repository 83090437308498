import { useRef, useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import { Card, Space, Tooltip } from "antd";
import { CodeOutlined } from "@ant-design/icons";
import { IProjectModel } from "../../stores/project/projectModel";
import { MSTContext } from "../../stores/Root";
import { AsyncOpState } from "../../types/enums/async-op-states";

import {
  fillSchemaExamplesWithData,
  jsonSchemaGetExamples,
} from "../../helpers/jsonSchema.helpers";
import "./UpdateProject.scss";
import { BlingsBtn } from "../../components/antd-extensions/blings-btn.component";
import { DynamicForm } from "@blings/dynamic-form";
import "./ProjectSchemas.scss";

import { CodeModeModal } from "../../components/CodeModeModal";
import { cleanEmptyStringInValue } from "../../helpers/object.helpers";
import { FEEDBACK_OPTIONS, FEEDBACK_TIME } from "../../consts";
import BlingsPlatformTheme from "../../config/antdTheme";
import { UploadFile } from "antd/lib";
type Props = {
  project: IProjectModel;
};
const saveWithFeedback = (
  saveFunction: () => void,
  setState: (arg0: AsyncOpState) => void
) => {
  setState(AsyncOpState.Saving);
  try {
    saveFunction();
    setTimeout(() => {
      setState(AsyncOpState.Success);
    }, FEEDBACK_TIME);
  } catch (error) {
    setTimeout(() => {
      setState(AsyncOpState.Error);
    }, FEEDBACK_TIME);
  }
  setTimeout(() => {
    setState(AsyncOpState.Changed);
  }, 2 * FEEDBACK_TIME);
};
export const UpdateProjectSchemas = observer(({ project }: Props) => {
  const {
    projectsStore: { updateOrCreateProjectStatus, selectedProject },
    liveControlStore: { getAssetsUploadStatus, uploadAssetToProject },
  } = useContext(MSTContext);

  const submitLiveControl = useRef<() => void>(() => {
    return;
  });
  const submitPerVideoData = useRef<() => void>(() => {
    return;
  });
  const [perVideoSaveState, setPerVideoSaveState] = useState<AsyncOpState>(
    updateOrCreateProjectStatus
  );
  const [controlSaveState, setControlSaveState] = useState<AsyncOpState>(
    updateOrCreateProjectStatus
  );
  const [perVideoCodeModalOpen, setPerVideoCodeModalOpen] = useState(false);
  const [liveControlCodeModalOpen, setLiveControlCodeModalOpen] =
    useState(false);

  return (
    <div className="project-schemas">
      {selectedProject ? (
        <div className="schemas">
          <div className="schema-card">
            <div className="content-wrapper">
              <div className="header">
                <span style={{ fontSize: 14, color: "#4d5566" }}>
                  Modify Your Dynamic Data's Default Parameters
                </span>

                <Tooltip title="Code mode">
                  <CodeOutlined
                    type="button"
                    className="ant-btn icon"
                    style={{ fontSize: "20px", cursor: "pointer" }}
                    onClick={() => {
                      setPerVideoCodeModalOpen(true);
                    }}
                  />
                </Tooltip>
              </div>
              <DynamicForm
                themeConfig={BlingsPlatformTheme}
                schema={
                  selectedProject.stateJsonSchemaStr &&
                  JSON.parse(selectedProject.stateJsonSchemaStr)
                }
                formData={
                  selectedProject.stateJsonSchemaStr
                    ? jsonSchemaGetExamples(
                        JSON.parse(selectedProject.stateJsonSchemaStr)
                      )
                    : {}
                }
                key={project.stateJsonSchemaStr}
                editable={true}
                readable={false}
                saveData={(data, schema) => {
                  fillSchemaExamplesWithData(schema, data);
                  selectedProject.savePerVideoData(schema);
                }}
                submit={submitPerVideoData}
                getAssetsUploadStatus={getAssetsUploadStatus}
                uploadAssetToProject={async (
                  file: File | UploadFile<any>,
                  s3Folder: string,
                  cb?: (key: string) => void
                ) => {
                  uploadAssetToProject(file, { s3Folder }, cb);
                }}
              />
              <div className="save-btn-wrapper">
                <BlingsBtn
                  className="save-btn"
                  opState={perVideoSaveState}
                  htmlType={"submit"}
                  btnTexts={FEEDBACK_OPTIONS}
                  onClick={() => {
                    saveWithFeedback(
                      submitPerVideoData.current,
                      setPerVideoSaveState
                    );
                  }}
                />
              </div>
            </div>
          </div>

          {!selectedProject.isControlDeprecated ? (
            <div className="schema-card">
              <Card
                title={"Live Control Schema:"}
                extra={
                  <Tooltip title="Code mode">
                    <CodeOutlined
                      type="button"
                      className="ant-btn icon"
                      style={{ fontSize: "20px", cursor: "pointer" }}
                      onClick={() => {
                        setLiveControlCodeModalOpen(true);
                      }}
                    />
                  </Tooltip>
                }
              >
                <DynamicForm
                  themeConfig={BlingsPlatformTheme}
                  schema={project.settingsSchema}
                  formData={project.settings || {}}
                  editable={true}
                  readable={false}
                  key={project.settingsJsonSchemaStr}
                  saveData={(data, schema) => {
                    fillSchemaExamplesWithData(schema, data);
                    //check project.settingsSchema against schema?
                    project.saveLiveControl(
                      cleanEmptyStringInValue(data),
                      schema
                    );
                  }}
                  submit={submitLiveControl}
                  getAssetsUploadStatus={getAssetsUploadStatus}
                  uploadAssetToProject={async (
                    file: File | UploadFile<any>,
                    s3Folder: string,
                    cb?: (key: string) => void
                  ) => {
                    uploadAssetToProject(file, { s3Folder }, cb);
                  }}
                />
                <Space style={{ marginLeft: "24px", paddingBottom: "1.5rem" }}>
                  <BlingsBtn
                    opState={controlSaveState}
                    htmlType={"submit"}
                    btnTexts={FEEDBACK_OPTIONS}
                    onClick={() => {
                      saveWithFeedback(
                        submitLiveControl.current,
                        setControlSaveState
                      );
                    }}
                  />
                </Space>
              </Card>
            </div>
          ) : null}
        </div>
      ) : (
        <div>Please select a project</div>
      )}
      {perVideoCodeModalOpen && (
        <CodeModeModal
          title="Example data"
          setVisible={setPerVideoCodeModalOpen}
          visible={perVideoCodeModalOpen}
          schema={selectedProject?.stateJsonSchema}
          onSave={(schema) => {
            selectedProject?.savePerVideoData(schema);
          }}
        />
      )}
      {liveControlCodeModalOpen && (
        <CodeModeModal
          title="Live control example data"
          setVisible={setLiveControlCodeModalOpen}
          visible={liveControlCodeModalOpen}
          schema={selectedProject?.settingsSchema}
          onSave={(schema, data) => {
            selectedProject?.saveLiveControl(data, schema);
          }}
          formData={selectedProject?.settings}
        />
      )}
    </div>
  );
});
