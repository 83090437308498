import React, { useCallback, useMemo, useRef } from "react";
// Import the Slate editor factory.
import { createEditor, BaseEditor, Descendant, Transforms } from "slate";

// Import the Slate components and React plugin.
import { Slate, Editable, withReact, ReactEditor } from "slate-react";
import { Button, Tag, Space, Popover } from "antd";
import TextStyleSelector from "./TextStyleSelector";
import { ConfigV2 } from "../DynamicThumbnailForm";
import { QuestionCircleOutlined } from "@ant-design/icons";

type CustomElement = {
  type: "paragraph" | "tag";
  children: (CustomText | CustomElement)[];
};
type CustomText = { text: string };
declare module "slate" {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

interface InputWithTagsProps {
  previewText: string;
  displayText: string;
  config: ConfigV2;
  setConfig: (config: ConfigV2) => void;
  textIndex: number;
  previewTextArr: (
    | string
    | {
        id: string;
        title?: string | undefined;
      }
  )[];
}
const InputWithTags = ({
  displayText,
  config,
  setConfig,
  textIndex,
  previewTextArr,
}: InputWithTagsProps) => {
  const input = useRef<(CustomText | CustomElement)[]>([]);

  const convertArrToDescendant = (
    arr: (
      | string
      | {
          id: string;
          title?: string | undefined;
        }
    )[]
  ): Descendant[] => {
    const children: (CustomText | CustomElement)[] = arr.map((item) => {
      if (typeof item === "string") {
        // This will match CustomText type
        return { text: item };
      }
      if (typeof item === "object" && item.id) {
        // CustomText with the text property from the object's id
        return { type: "tag", children: [{ text: item.id }] };
      }
      // In case the item doesn't match the expected types, return an empty text
      return { text: "" };
    });

    // Wrap children in a paragraph element
    return [
      {
        type: "paragraph",
        children,
      },
    ];
  };

  const initialValue: Descendant[] = convertArrToDescendant(previewTextArr);

  const editor = useMemo(() => {
    const edt = withReact(createEditor());
    const { isInline, isVoid } = edt;

    edt.isInline = (element) => {
      return element.type === "tag" ? true : isInline(element);
    };

    edt.isVoid = (element) => {
      return element.type === "tag" ? true : isVoid(element);
    };

    return edt;
  }, []);
  const renderElement = useCallback((props: any) => {
    switch (props.element.type) {
      case "tag":
        return <TagComponent {...props} />;
      default:
        return <DefaultElement {...props} />;
    }
  }, []);

  const handleOnClick = (id: string) => {
    const str: CustomText = { text: `param${id}` };
    const block: CustomElement = { type: "tag", children: [str] };
    Transforms.insertNodes(editor, [block]);
    Transforms.move(editor);
  };

  function isCustomElement(
    elem: CustomText | CustomElement
  ): elem is CustomElement {
    return (elem as CustomElement).type !== undefined;
  }
  function isCustomText(elem: CustomText | CustomElement): elem is CustomText {
    return (elem as CustomText).text !== undefined;
  }
  return (
    <Slate
      editor={editor}
      initialValue={initialValue}
      //update the textTemplate for the config using onChange below
      onChange={(newValue) => {
        const formattedArr: (string | { id: string; title: string })[] = [];
        for (let i = 0; i < newValue.length; i++) {
          if (isCustomElement(newValue[i])) {
            const newElement = newValue[i] as CustomElement;
            if (i > 0) {
              formattedArr.push("\n");
            }
            if (input.current != newElement.children) {
              const intermArr = newElement.children.map(
                (item: CustomText | CustomElement) => {
                  if (isCustomText(item)) {
                    return item.text;
                  } else if (
                    isCustomElement(item) &&
                    isCustomText(item.children[0])
                  ) {
                    const param = item.children[0].text;
                    return { id: param, title: param };
                  } else return "should never be reached";
                }
              );
              formattedArr.push(...intermArr);
            }
            input.current = newValue;
          }
        }
        const nextState: ConfigV2 = JSON.parse(JSON.stringify(config));
        nextState.texts[textIndex].textTemplate = formattedArr;
        setConfig(nextState);
      }}
    >
      <div className="slateContainer">
        <div className="aboveEditorContainer">
          <p className="textAboveEditor">{displayText}</p>
          <Space>
            <label style={{ marginRight: "4px" }}>Add</label>
            <div>
              <Button
                className="paramButtons"
                style={{ marginRight: "6px" }}
                onClick={() => handleOnClick("1")}
              >
                Param 1
              </Button>
              <Button
                className="paramButtons"
                disabled={
                  !config.texts.some((textConfig) => {
                    return textConfig.textTemplate.some(
                      (word) => typeof word === "object" && word.id === "param1"
                    );
                  })
                }
                onClick={() => handleOnClick("2")}
              >
                Param 2
              </Button>
            </div>
          </Space>
        </div>
        <div style={{ display: "flex" }}>
          <Editable
            renderElement={renderElement}
            className="textEditor"
            disableDefaultStyles
            placeholder="Customize this text"
          />
          <Popover
            className="tooltip"
            placement="right"
            overlayInnerStyle={{ width: "250px" }}
            style={{ zIndex: "0" }}
            getPopupContainer={(trigger) =>
              trigger?.parentElement || document.body
            }
            content={
              <div className="extra-info-popover">
                Overlay this text on the image: Use <b>Param1</b>, <b>Param2</b>{" "}
                as parameters.
              </div>
            }
          >
            <div>
              <QuestionCircleOutlined
                style={{ color: "var(--blings_icon_gray)", cursor: "pointer" }}
              />
            </div>
          </Popover>
        </div>

        <TextStyleSelector
          config={config}
          setConfig={setConfig}
          textIndex={textIndex}
        />
      </div>
    </Slate>
  );
};

const TagComponent = (props: any) => {
  const text = props.element.children.map((child: any) => child.text).join("");
  const properText = text.charAt(0).toUpperCase() + text.slice(1); //convert first char to upper case
  return (
    <span
      contentEditable={false}
      {...props.attributes}
      style={{ display: "inline-block", margin: "0 4px" }}
    >
      <Tag style={{ fontSize: "1rem", margin: 0 }}>
        <span style={{ color: "red" }}>{properText}</span>
      </Tag>
      {props.children}
    </span>
  );
};

const DefaultElement = (props: any) => {
  return (
    <p
      style={{
        whiteSpace: "pre-wrap",
        marginTop: ".5em",
        marginBottom: ".5em",
      }}
      {...props.attributes}
    >
      {props.children}
    </p>
  );
};

export default InputWithTags;
