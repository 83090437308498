import { IProjectModel } from "../../stores/project/projectModel";
import { observer } from "mobx-react-lite";
import { useNavigate, useLocation } from "react-router-dom";
import { PATHS, PROJECT_PATHS, toPath } from "../../PATHS";
import { useContext, useEffect, useState } from "react";
import { MSTContext } from "../../stores/Root";
import "./SceneView.scss";
import { Menu, MenuProps } from "antd";

type Props = {
  project: IProjectModel;
  selectedScene: string | undefined;
};

type MenuItem = Required<MenuProps>["items"][number];

const ALL_SCENSES_KEY = "blings_all_scenes_key_menu";
const BRANDING_KEY = "_branding";

function getSelectedItemName(
  pathname: string,
  selectedScene: string | undefined
) {
  let sceneNameFromUrl = "";
  const match = pathname.match(/[^/]+(?=\/?$)/);

  if (match) {
    sceneNameFromUrl = match[0];
  }

  return sceneNameFromUrl === BRANDING_KEY ? sceneNameFromUrl : selectedScene;
}

function prepareListItems(
  videoPartNames: string[] | undefined,
  fromUseCase: boolean
): MenuItem[] {
  const sceneList: MenuItem[] = videoPartNames?.map((vp) => ({
    key: vp,
    label: (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        {vp}
      </div>
    ),
    type: undefined,
  })) as MenuItem[];

  sceneList?.unshift({
    key: ALL_SCENSES_KEY,
    label: (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        All Scenes
      </div>
    ),
    type: undefined,
  });

  const items = [
    fromUseCase && {
      key: BRANDING_KEY,
      label: "",
      type: "group",
      children: [
        {
          key: BRANDING_KEY,
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              General Customization
            </div>
          ),
          type: undefined,
        },
      ],
    },
    {
      label: fromUseCase
        ? "Or, Refine content for:"
        : "Refine the content of scene:",
      className: "refine-content-title",
      key: ALL_SCENSES_KEY,
      type: "group",
      children: sceneList,
    },
  ] as MenuItem[];

  return items;
}

const SceneView = observer(({ project, selectedScene }: Props) => {
  const history = useNavigate();
  const location = useLocation();

  const { editVideoStore, projectsStore } = useContext(MSTContext);
  const { setSearchView, setCurrentSearch } = editVideoStore;
  const { instantiatedFromUseCase } = projectsStore.selectedProject || {};
  const [videoPartNames, setVideoPartNames] = useState<string[] | undefined>(
    []
  );

  const selectedItem = getSelectedItemName(location.pathname, selectedScene);

  const onClick = (e: any) => {
    setSearchView(false);
    try {
      if (e.key === ALL_SCENSES_KEY) {
        history(toPath(PATHS.project, project.id, PROJECT_PATHS.editContent));
      } else {
        history(
          toPath(PATHS.project, project.id, PROJECT_PATHS.editContent, e.key)
        );
      }
    } catch (e) {}
  };

  const handleOnAllScenes = () => {
    setSearchView(true);
    setCurrentSearch("");
    history(toPath(PATHS.project, project.id, PROJECT_PATHS.editContent));
  };
  useEffect(() => {
    const currentVideoParts = project.workspaceVideoParts?.map((vp) => vp.name);
    setVideoPartNames(currentVideoParts);
  }, [project.workspaceVideoParts.length, selectedScene, project]);

  const items = prepareListItems(videoPartNames, !!instantiatedFromUseCase);

  return (
    <div className="scene-view">
      <Menu
        mode="inline"
        openKeys={[ALL_SCENSES_KEY, BRANDING_KEY]}
        onClick={onClick}
        onOpenChange={handleOnAllScenes}
        expandIcon={() => <div></div>}
        selectedKeys={[selectedItem || ALL_SCENSES_KEY]}
        items={items}
      />
    </div>
  );
});
export default SceneView;
