import { Instance, types } from "mobx-state-tree";
import { ConfigV2 } from "../../view/IntegrationPage/SiteIntegration/DataIntegration/DynamicThumbnailForm/DynamicThumbnailForm";
import { saveDynamicThumbnails } from "../../utils/projectTools";
import { getConfigAndImage } from "./dynamicThumbnailTools";
import { asyncOpStateType } from "../async-op-states";
import { AsyncOpState } from "../../types/enums/async-op-states";
import _ from "lodash";

export const DynamicThumbnailModel = types.model("DynamicThumbnail", {
  id: types.string,
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
});
export const DynamicThumbnailsModel = types
  .model("DynamicThumbnails", {
    DynamicThumbStatus: asyncOpStateType,
    id: types.maybeNull(types.string),
    thumbnails: types.maybeNull(
      types.array(types.maybeNull(DynamicThumbnailModel))
    ),
    projectId: types.string,
    createdAt: types.optional(types.Date, new Date()),
    configV2: types.maybeNull(types.frozen<ConfigV2>()),
    file: types.maybeNull(types.frozen<File>()), //allows it to be null and then also allows it to accept any type...will have to validate it is a file then
  })
  .views((self) => ({
    get config() {
      return self.configV2;
    },
  }))
  .actions((self) => {
    return {
      setConfig(newConfig: ConfigV2) {
        self.configV2 = newConfig;
      },
      setFileData(newFile: File) {
        self.file = newFile;
      },
      changeDynamicThumbnailStatus(newStatus: AsyncOpState) {
        self.DynamicThumbStatus = newStatus;
      },
      convertBase64ToImageFile(base64: string, fileName: string) {
        const byteCharacters = atob(base64.split(",")[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "image/png" }); // specify the image type if known
        const file = new File([blob], fileName, { type: "image/png" });

        return file;
      },

      async afterCreate() {
        // This is a mobx built in special name which will run after create
        if (self.id) {
          try {
            await this.fetchData();
            return;
          } catch (err) {
            this.setDynamicThumbnailId(null);
            console.log(err);
          }
        }
        const initialConfig: ConfigV2 = {
          version: 2,
          previewParams: ["John Doe", ""],
          playIcon: {
            enabled: true,
            size: 300,
            color: "#000000",
            opacity: 1,
          },
          texts: [
            {
              breakpoint: 40,
              position: {
                x: 50,
                y: 20,
              },
              anchor: "middle",
              color: "#000000",
              stroke: { color: "#000000", thickness: 1, opacity: 0 },
              weight: "Bold",
              fontFamily: "Open Sans",
              fontSize: 30,
              textTemplate: [
                "Hey ",
                {
                  id: "param1",
                  title: "param1",
                },
                ", Check this out!",
              ],
            },
            {
              breakpoint: 40,
              position: {
                x: 50,
                y: 85,
              },
              anchor: "middle",
              color: "#000000",
              stroke: { color: "#000000", thickness: 1, opacity: 0 },
              weight: "Bold",
              fontFamily: "Open Sans",
              fontSize: 30,
              textTemplate: [""],
            },
          ],
          width: 0,
          height: 0,
          projectId: self.projectId,
        };
        this.setConfig(initialConfig);
      },
      async fetchData() {
        if (self.id) {
          const data = await getConfigAndImage(self.id);
          if (!data?.config.version || data.config.version !== 2) {
            console.log(self.config);

            throw new Error("Config is the wrong version");
          }
          this.setConfig(data.config);
          const imgFile = this.convertBase64ToImageFile(
            data.img,
            "submittedImg.png"
          );
          this.setFileData(imgFile);
        }
      },
      async saveDynamicThumbnailId(id: string) {
        const dynamicThumbnails = self.thumbnails || [];
        if (!dynamicThumbnails[0]) {
          dynamicThumbnails[0] = {
            id,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            name: null,
          };
        } else {
          dynamicThumbnails[0].id = id;
          dynamicThumbnails[0].updatedAt = new Date().toISOString();
        }
        const dynamicThumbnailsAsPlainObject = dynamicThumbnails.map((dt) => {
          return dt == null
            ? null
            : {
                id: dt.id,
                createdAt: dt?.createdAt || null,
                updatedAt: dt?.updatedAt || null,
                name: dt?.name || null,
              };
        });
        await saveDynamicThumbnails(
          self.projectId,
          dynamicThumbnailsAsPlainObject
        );
        this.setDynamicThumbnailId(id);
      },
      setDynamicThumbnailId(id: string | null) {
        self.id = id;
      },
      handleFileChange(e: any, onSuccess: any, onError: any) {
        this.changeDynamicThumbnailStatus(AsyncOpState.Saving);
        const file = e.file;
        if (file) {
          const acceptedTypes = "image/png";

          if (acceptedTypes.split("/")[0] === file.type.split("/")[0]) {
            //check if they are of type image
            const reader = new FileReader();

            reader.onload = (event) => {
              const img = new Image();
              img.onload = () => {
                if (self.configV2) {
                  const nextState: ConfigV2 = JSON.parse(
                    JSON.stringify(self.configV2)
                  );
                  nextState.width = img.width;
                  nextState.height = img.height;
                  nextState.playIcon = _.cloneDeep(nextState.playIcon);
                  nextState.playIcon.size = Math.min(
                    img.width,
                    img.height,
                    nextState.playIcon.size
                  );
                  nextState.playIcon.maxSize = Math.min(img.width, img.height);
                  this.setConfig(nextState);
                }
                // Use the height and width as needed
                onSuccess({}, file);
              };
              img.src = event.target?.result as string;
            };

            reader.readAsDataURL(file);
            this.setFileData(file);
            this.changeDynamicThumbnailStatus(AsyncOpState.Success);
          } else {
            this.changeDynamicThumbnailStatus(AsyncOpState.Error);
            // Invalid file type
            onError(new Error("Error: You must upload an image file"), file);
            alert("Error: You must upload an image file");
          }
        }
      },
    };
  });
export type IDynamicThumbnailModel = Instance<typeof DynamicThumbnailsModel>;
