export const VolumeUp = ({ color = "white" }: { color?: string }) => {
  return (
    <svg
      color={color}
      style={{ width: "100%", height: "100%" }}
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.00185 12.0001C3.99906 13.2298 3.94419 14.907 4.70494 15.5339C5.41453 16.1187 5.91395 15.968 7.20945 16.0631C8.50587 16.1591 11.242 19.97 13.3512 18.7646C14.4393 17.9089 14.5202 16.1151 14.5202 12.0001C14.5202 7.88515 14.4393 6.09135 13.3512 5.23571C11.242 4.02938 8.50587 7.84121 7.20945 7.93717C5.91395 8.03225 5.41453 7.88157 4.70494 8.46635C3.94419 9.09328 3.99906 10.7705 4.00185 12.0001Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0843 5.90399C22.6344 9.57501 22.6427 14.4174 20.0843 18.0956"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5813 8.31442C18.8926 10.6051 18.8926 13.4025 17.5813 15.6861"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Send = ({ color = "white" }: { color: string }) => {
  return (
    <svg
      color={color}
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8744 6.36926L7.58178 10.7077L2.69958 7.65404C2.00006 7.21639 2.14557 6.15386 2.93679 5.92248L14.5284 2.52786C15.2529 2.3155 15.9244 2.9929 15.7092 3.71978L12.2798 15.3033C12.0449 16.0957 10.9884 16.2372 10.5549 15.5347L7.5795 10.7084"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Mute = ({ color = "white" }: { color?: string }) => {
  return (
    <svg
      color={color}
      style={{ width: "100%", height: "100%" }}
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Iconly/Light/Volume Off">
        <g id="Volume Off">
          <path
            id="Stroke 1"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.5329 9.46704L7.71357 16.2869C7.53632 16.1842 7.36841 16.1189 7.21915 16.1096C5.92245 16.0163 5.4187 16.1656 4.70971 15.5592C3.94476 14.9061 4.00073 13.1615 4.00073 11.8834C4.00073 10.6052 3.94476 8.86063 4.70971 8.20757C5.4187 7.60115 5.92245 7.75975 7.21915 7.65713C8.51584 7.55451 11.2585 3.59882 13.3761 4.84897C14.2344 5.54868 14.4676 6.8548 14.5329 9.46704Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Stroke 3"
            d="M14.5329 13.9172C14.4955 16.7907 14.2717 18.1901 13.3761 18.9178C12.3966 19.4962 11.2865 18.9644 10.2416 18.2088"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Stroke 5"
            d="M4.00122 20L7.71406 16.2869L14.5334 9.46706L20 4"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};
export const CheckIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.993 10.5697L11.375 15.1877C11.228 15.3347 11.037 15.4077 10.845 15.4077C10.652 15.4077 10.461 15.3347 10.314 15.1877L8.005 12.8787C7.712 12.5857 7.712 12.1107 8.005 11.8177C8.298 11.5247 8.772 11.5247 9.065 11.8177L10.845 13.5967L14.932 9.50866C15.225 9.21566 15.7 9.21566 15.993 9.50866C16.286 9.80166 16.286 10.2767 15.993 10.5697ZM12 2.84766C6.762 2.84766 2.5 7.10966 2.5 12.3477C2.5 17.5867 6.762 21.8477 12 21.8477C17.238 21.8477 21.5 17.5867 21.5 12.3477C21.5 7.10966 17.238 2.84766 12 2.84766Z"
        fill="currentColor"
      />
    </svg>
  );
};
export const AfterEffectskIcon = () => {
  return (
    <svg
      width="31"
      height="32"
      viewBox="0 0 31 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="0.806641"
        width="30.375"
        height="30.3876"
        rx="6"
        fill="#E8E8FF"
      />
      <path
        d="M20.2378 21.2267C19.2 21.2267 18.2508 20.8342 17.5547 20.0872C16.9345 19.4161 16.5169 18.4792 16.4409 17.5549C16.4409 17.5169 16.4409 17.4662 16.4409 17.4283C16.3903 16.5293 16.6434 15.681 17.1244 15.0099C17.7445 14.1489 18.7444 13.6298 19.7822 13.6045C20.6302 13.6172 21.4655 13.9084 22.0983 14.4908C22.7184 15.0606 23.1108 15.8329 23.2247 16.6686C23.2373 16.8078 23.25 17.1624 23.25 17.1624C23.25 17.8461 22.7184 18.3779 22.0603 18.3779H18.6305C18.7191 18.5298 18.8203 18.6818 18.9342 18.7957C19.7316 19.644 20.8959 19.2768 21.3516 19.0743C21.8325 18.859 22.3894 19.0743 22.6045 19.5681C22.8197 20.0492 22.6045 20.6063 22.1109 20.8215C21.4781 21.1001 20.8327 21.2394 20.2125 21.2394L20.2378 21.2267ZM18.4659 16.4787H21.225C21.1364 16.2507 20.9972 16.0482 20.82 15.8962C20.5416 15.643 20.1745 15.5037 19.8202 15.5164C19.3772 15.5164 18.9216 15.7696 18.6558 16.1368C18.5798 16.2381 18.5166 16.3647 18.4659 16.4913V16.4787Z"
        fill="#000051"
      />
      <path
        d="M15.0352 21.2C14.6681 21.2 14.3138 20.9848 14.1619 20.6176L11.5547 14.2742L8.94751 20.6176C8.74501 21.0987 8.20078 21.3393 7.70719 21.1367C7.22625 20.9341 6.98577 20.377 7.18827 19.8959L10.6687 11.4253C10.9598 10.7163 12.1369 10.7163 12.428 11.4253L15.9084 19.8959C16.1109 20.377 15.8831 20.9341 15.3895 21.1367C15.2756 21.1873 15.1491 21.2127 15.0225 21.2127L15.0352 21.2Z"
        fill="#000051"
      />
      <path
        d="M13.7831 18.1746H9.30273C8.78383 18.1746 8.35352 17.7441 8.35352 17.225C8.35352 16.7059 8.78383 16.2754 9.30273 16.2754H13.7831C14.302 16.2754 14.7323 16.7059 14.7323 17.225C14.7323 17.7441 14.302 18.1746 13.7831 18.1746Z"
        fill="#000051"
      />
    </svg>
  );
};
export const AIIcon = () => {
  return (
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.152344" width="30" height="30" rx="6" fill="#F5EDFF" />
      <path
        d="M13.8507 14.2253C14.4064 13.6007 15.3633 13.5449 15.9879 14.1006C16.6125 14.6563 16.6683 15.6132 16.1126 16.2378L9.39573 23.6447C8.84 24.2693 7.88315 24.3252 7.25855 23.7694C6.63394 23.2137 6.57811 22.2569 7.13384 21.6323L13.8507 14.2253Z"
        stroke="#9747FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.1398 11.964L19.0269 11.6584C18.6012 10.5061 17.694 9.59764 16.5434 9.17126L16.2383 9.05819L16.5434 8.94511C17.694 8.51873 18.6012 7.61023 19.0269 6.45796L19.1398 6.15234L19.2528 6.45796C19.6785 7.61023 20.5857 8.51873 21.7363 8.94511L22.0415 9.05819L21.7363 9.17126C20.5857 9.59764 19.6785 10.5061 19.2528 11.6584L19.1398 11.964Z"
        stroke="#9747FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3281 11.4757C11.1126 10.7666 10.5585 10.2117 9.85059 9.99588C10.5585 9.78007 11.1126 9.22516 11.3281 8.51611C11.5436 9.22516 12.0977 9.78007 12.8056 9.99588C12.0977 10.2117 11.5436 10.7666 11.3281 11.4757Z"
        stroke="#9747FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.042 17.3923C21.866 16.8131 21.4134 16.3598 20.835 16.1835C21.4134 16.0072 21.866 15.5538 22.042 14.9746C22.2181 15.5538 22.6707 16.0072 23.249 16.1835C22.6707 16.3598 22.2181 16.8131 22.042 17.3923Z"
        stroke="#9747FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5352 15.7524L14.7408 17.7147"
        stroke="#9747FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.7971 20.5049H18.7871"
        stroke="#9747FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const BookIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" rx="6" fill="#FFEFF7" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1477 6.75C9.13064 6.75 8.30664 7.574 8.30664 8.59103V18.7813C8.80859 18.4614 9.40454 18.2762 10.0436 18.2762H21.6873V7.1987C21.6873 6.9509 21.4866 6.75 21.2386 6.75H10.1477ZM21.6886 19.7762H10.0436C9.08472 19.7762 8.30664 20.5536 8.30664 21.5131C8.30664 22.4725 9.08472 23.25 10.0436 23.25H21.2454C21.4925 23.25 21.6927 23.0507 21.6941 22.8043L21.6886 19.7762ZM6.80664 21.5131V8.59103C6.80664 6.74557 8.30221 5.25 10.1477 5.25H21.2386C22.3158 5.25 23.1873 6.12326 23.1873 7.1987V19.0262L23.1941 22.8038V22.8074C23.1909 23.8817 22.3191 24.75 21.2454 24.75H10.0436C8.25668 24.75 6.80664 23.3013 6.80664 21.5131ZM10.9214 9.27906C11.263 8.91284 11.7367 8.72572 12.2508 8.72572H17.8843C18.3992 8.72572 18.8736 8.91238 19.2154 9.2796C19.5515 9.64072 19.7036 10.1156 19.7036 10.5995V12.3771C19.7036 12.8607 19.5514 13.3355 19.2155 13.6965C18.8739 14.0637 18.3997 14.2509 17.8843 14.2509H12.2508C11.7357 14.2509 11.2617 14.0635 10.9202 13.6967C10.5843 13.3359 10.4315 12.8612 10.4315 12.3771V10.5995C10.4315 10.1142 10.5853 9.6395 10.9214 9.27906ZM12.0184 10.3021C11.9841 10.3389 11.9315 10.4261 11.9315 10.5995V12.3771C11.9315 12.5508 11.984 12.638 12.0182 12.6747C12.0466 12.7053 12.1073 12.7509 12.2508 12.7509H17.8843C18.0287 12.7509 18.0891 12.7051 18.1172 12.6748C18.1511 12.6384 18.2036 12.5512 18.2036 12.3771V10.5995C18.2036 10.4247 18.151 10.3377 18.1174 10.3016C18.0895 10.2716 18.0291 10.2257 17.8843 10.2257H12.2508C12.1082 10.2257 12.0473 10.2711 12.0184 10.3021Z"
        fill="#FF0088"
      />
    </svg>
  );
};
export const CopyIcon = () => {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="30" height="30" rx="6" fill="#FFF2EC" />
      <path
        d="M16.25 8H11C10.6022 8 10.2206 8.15804 9.93934 8.43934C9.65804 8.72064 9.5 9.10218 9.5 9.5V21.5C9.5 21.8978 9.65804 22.2794 9.93934 22.5607C10.2206 22.842 10.6022 23 11 23H20C20.3978 23 20.7794 22.842 21.0607 22.5607C21.342 22.2794 21.5 21.8978 21.5 21.5V13.25L16.25 8Z"
        stroke="#FFA87F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.25 8V13.25H21.5"
        stroke="#FFA87F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const CurrentStepIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="0.587891"
        width="19"
        height="19"
        rx="9.5"
        stroke="#1B2B4B"
      />
      <rect x="5.5" y="5.08789" width="10" height="10" rx="5" fill="#1B2B4B" />
    </svg>
  );
};
export const NextStepCircleIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="0.587891"
        width="19"
        height="19"
        rx="9.5"
        stroke="#CDD5E5"
      />
    </svg>
  );
};
export const CircleCheckedIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="0.587891"
        width="19"
        height="19"
        rx="9.5"
        stroke="#1B2B4B"
      />
      <path
        d="M9.16681 12.4789L6.97147 10.2835L6.02881 11.2262L9.16681 14.3642L15.6381 7.89286L14.6955 6.9502L9.16681 12.4789Z"
        fill="#1B2B4B"
      />
    </svg>
  );
};
export const LeftArrow = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.75 9.5L4.25 9.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 14.75L4.25 9.5L9.5 4.25"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const RightArrow = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.25 9.5L14.75 9.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 4.25L14.75 9.5L9.5 14.75"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const CloseXIcon = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6033 4.89648L4.80957 13.6902"
        stroke="#515458"
        strokeWidth="1.95417"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.80957 4.89648L13.6033 13.6902"
        stroke="#515458"
        strokeWidth="1.95417"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const LeftOutlinedIcon = () => {
  return (
    <svg
      width="6"
      height="12"
      viewBox="0 0 6 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.25 1.5L0.75 6L5.25 10.5"
        stroke="#45464E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
