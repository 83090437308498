import { useEffect, useState, memo, useMemo } from "react";
import "./UseCaseTemplate.scss";
import {
  FontInput,
  OverlayInput,
  UseCase,
  UseCaseTag,
  VidPartInput,
} from "../../../API";
import { Button, Select, Input } from "antd";
import SdkPlayer, { type SdkPlayerProps } from "../../../components/SdkPlayer";
import { getENV } from "../../../config";
import Stepes from "../../../components/Steps/Steps";
import Carousel, {
  type CarouselProps,
} from "../../../components/Carousel/Carousel";
import { UseCaseTemplateEditor } from "./UseCaseTemplateEditor";
import { IJSONObject } from "@blings/blings-player";
import { get } from "aws-amplify/api";
import { NEW_REST_API, REST_API_V2__GET_USE_CASES } from "../../../consts";
import { IAccountModel } from "../../../stores/accountStore";
import { useNavigate } from "react-router-dom";
import { PATHS, PROJECT_PATHS, toPath } from "../../../PATHS";
import { font2Hash } from "../../../utils/font";
interface IProjectPublic {
  id: string | undefined;
  title: string;
  settings: string;
  videoParts: Array<VidPartInput>;
}
const steps = [
  {
    title: "Preview",
    content: "",
  },
  {
    title: "Customize",
    content: "",
  },
  {
    title: "Done",
    content: "",
  },
];

const MemoizedSdkPlayer = memo<SdkPlayerProps>(SdkPlayer);

export default function UseCaseTemplate({
  usecase,
  tags = [],
  accountId,
  accounts,
  instantiateUseCase,
  loadProjectInfoIntoProjectsArray,
}: {
  usecase: UseCase | null;
  tags: Array<UseCaseTag> | [];
  accountId: string;
  accounts: Array<IAccountModel> | [];
  instantiateUseCase: (
    accountId: string,
    useCaseId: string,
    liveControl: IJSONObject,
    fontOverrides: Array<FontInput>,
    overlay: OverlayInput,
    title?: string
  ) => Promise<string | undefined>;
  loadProjectInfoIntoProjectsArray: (projectId: string) => void;
}) {
  const [current, setCurrent] = useState(0);
  const [isProjectNameValid, setIsProjectNameValid] = useState(true);
  const [renameProjectTitle, setRenameProjectTitle] = useState<string>("");
  const [isRenameStep, setIsRenameStep] = useState(false);
  const [isSavingTemplate, setIsSavingTemplate] = useState(false);
  const [newUseCaseProjectId, setNewUseCaseProjectId] = useState<string>("");
  const [liveControl, setLiveControl] = useState<IJSONObject>({});
  const [overlayAudio, setOverlayAudio] = useState<{
    url: string;
    loop: boolean;
    volume: number;
  }>({ url: "", loop: true, volume: 1 });
  const [fontOverrides, setFontOverrides] = useState<Array<FontInput>>([]);
  const [selectedAccountId, setSelectedAccountId] = useState<
    string | undefined
  >(undefined);
  const [deafaultLiveControl, setDeafaultLiveControl] = useState<IJSONObject>(
    {}
  );
  const [fontsInVideoParts, setFontsInVideoParts] = useState<Array<FontInput>>(
    []
  );

  const multiAccounts = accounts.map((account) => ({
    label: account.name,
    value: account.id,
  }));

  const navigate = useNavigate();
  useEffect(() => {
    async function fetchProject() {
      if (!usecase) return;
      try {
        const response = await get({
          apiName: NEW_REST_API,
          path: `${REST_API_V2__GET_USE_CASES}/${usecase.id}/project`,
        }).response;

        const project =
          (await response.body.json()) as unknown as IProjectPublic;
        setLiveControl(JSON.parse(project.settings));
        setDeafaultLiveControl(JSON.parse(project.settings));
        const uniqueFonts = Object.values(
          project.videoParts.reduce<Record<string, FontInput>>((acc, part) => {
            if (part.fonts) {
              part.fonts.forEach((font) => {
                acc[font2Hash(font)] = font;
              });
            }
            return acc;
          }, {})
        );
        setFontsInVideoParts(uniqueFonts);
        setRenameProjectTitle(usecase.name);
      } catch (error) {
        console.error("Error fetching project or video parts:", error);
      }
    }

    fetchProject();
  }, [usecase]);

  const sdkParams = useMemo(() => {
    if (!usecase) return null;
    return {
      project: {
        id: usecase.projectId,
        env: getENV(),
        liveControlData: liveControl,
      },
      data: {},
      scenes: [{ lineup: usecase.lineupId || "" }],
      settings: {
        overlay: { audio: [overlayAudio] },
        fontOverrides,
      },
    } as SdkPlayerProps["sdkParams"];
  }, [usecase, liveControl, overlayAudio, fontOverrides]);

  if (!usecase || !sdkParams) return null;

  const images = [
    {
      type: "player",
      content: sdkParams,
    },
    ...(usecase.images?.map((image) => ({
      type: "image",
      content: image,
    })) || []),
  ] as CarouselProps["slides"];

  const handleSaveLiveControl = (data: any) => {
    setLiveControl((prev) => ({
      ...prev,
      ...data,
    }));
  };

  const isRevertDisabled =
    JSON.stringify(liveControl) === JSON.stringify(deafaultLiveControl);

  const next = () => {
    setCurrent(current + 1);
  };

  const getTextBtn = () => {
    if (current === 0) {
      if (
        isSavingTemplate ||
        Object.keys(liveControl).length === 0 ||
        !isProjectNameValid
      )
        return "Loading...";
      return "Customize";
    } else if (current === 1) return "Continue";
    else if (current === 2)
      if (isSavingTemplate) return "Generating Project...";
    return "Start Editing";
  };

  const items = steps.map((item) => ({
    key: item.title,
    label: item.title,
  }));

  const validateProjectName = (e: any) => {
    const { value } = e.target;
    setRenameProjectTitle(value);
    if (value.trim().length < 2) {
      setIsProjectNameValid(false);
    } else if (value.trim().length > 40) {
      setIsProjectNameValid(false);
    } else {
      setIsProjectNameValid(true);
    }
  };

  const handleInstantiateUseCase = async () => {
    setIsSavingTemplate(true);
    next();
    try {
      const defaultAccountId = selectedAccountId || accountId;
      const newProjectId = await instantiateUseCase(
        defaultAccountId,
        usecase.id,
        liveControl,
        fontOverrides,
        { audio: [overlayAudio] },
        renameProjectTitle
      );

      if (!newProjectId) return;
      await loadProjectInfoIntoProjectsArray(newProjectId);
      setNewUseCaseProjectId(newProjectId);
    } catch (e) {}

    setIsSavingTemplate(false);
  };

  const handleNextBtn = async () => {
    if (current === 0) next();
    else if (current === 1) {
      if (!isRenameStep) {
        setIsRenameStep(true);
      } else {
        handleInstantiateUseCase();
      }
    } else if (current === 2) {
      navigate(
        toPath(
          PATHS.project,
          newUseCaseProjectId,
          PROJECT_PATHS.editContent + "/_branding"
        )
      );
    }
  };

  const getStepContentRight = () => {
    switch (current) {
      case 0:
        return (
          <div className="use-case-title">
            <h1>{usecase.name}</h1>
            {tags.length > 0 && (
              <div className="tag-list">
                {tags.map((tag) => (
                  <div key={tag.tag} className="usecase-tag">
                    {tag.tag}
                  </div>
                ))}
              </div>
            )}
            <p>{usecase.shortDescription} </p>
          </div>
        );
      case 1:
        if (isRenameStep) {
          return (
            <div className="rename-wrapper">
              <div className="title">Customize Your Use Case </div>
              <div className="rename-input-wrapper">
                <div className="subtitle">
                  Give this use case a title for your project page
                </div>
                <Input
                  className="rename-input"
                  placeholder="Project Name"
                  value={renameProjectTitle}
                  onChange={validateProjectName}
                />
              </div>
              {multiAccounts?.length > 1 && (
                <div className="select-account-wrapper">
                  <div className="subtitle">
                    Select an account to use this template
                  </div>
                  <Select
                    placeholder="Select account"
                    className="select-account"
                    value={selectedAccountId}
                    options={multiAccounts}
                    onChange={(value) => {
                      setSelectedAccountId(value);
                    }}
                  />
                </div>
              )}
            </div>
          );
        } else {
          return (
            <UseCaseTemplateEditor
              fontOverrides={fontOverrides}
              setFontOverrides={setFontOverrides}
              handleLiveControlChange={handleSaveLiveControl}
              liveControl={liveControl as IJSONObject}
              projectId={usecase.projectId}
              accountId={accountId}
              setIsSavingTemplate={setIsSavingTemplate}
              overlayAudio={overlayAudio}
              handleOverlayAudioChange={setOverlayAudio}
              fontsInVideoParts={fontsInVideoParts}
            />
          );
        }
      case 2:
        return (
          <div className="step-done">
            <div className="preparing">
              <div className="title">
                {isSavingTemplate ? "Generating..." : "Congratulations!"}
              </div>
              <p className="message">
                {isSavingTemplate
                  ? "Your Project is being prepared and will be ready for editing shortly."
                  : "Your project is ready for editing."}
              </p>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const isNextBtnDisabled = () => {
    if (
      isSavingTemplate ||
      Object.keys(liveControl).length === 0 ||
      !isProjectNameValid
    )
      return true;
    if (current === 0) return false;
    else if (
      current === 1 &&
      isRenameStep &&
      multiAccounts.length > 1 &&
      !selectedAccountId
    )
      return true;
    else if (current === 2) return isSavingTemplate;
    return false;
  };

  return (
    <div className="use-case-main-wrapper">
      <div className="use-case-left-side">
        {current === 0 ? (
          <Carousel slides={images} showPagination={true} />
        ) : (
          <div className="customize-video-wrapper">
            <MemoizedSdkPlayer sdkParams={sdkParams} />
          </div>
        )}
      </div>
      <div className="use-case-right-side">
        <div className="steps-wrapper">
          <div className="use-case-content">
            <Stepes steps={items} currentStep={current} />
            {getStepContentRight()}
          </div>
        </div>

        {current < steps.length && (
          <div
            className="next-btn-wrapper"
            style={{ marginBottom: current === 0 ? "35px" : 0 }}
          >
            {current === 1 && !isRenameStep ? (
              <Button
                className="revert-btn"
                disabled={isRevertDisabled}
                onClick={() => setLiveControl(deafaultLiveControl)}
              >
                Revert
              </Button>
            ) : null}
            <Button
              className="next-step-btn"
              onClick={() => handleNextBtn()}
              disabled={isNextBtnDisabled()}
            >
              {getTextBtn()}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
