/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onUpdateProjectSub = /* GraphQL */ `subscription OnUpdateProjectSub($id: ID) {
  onUpdateProjectSub(id: $id) {
    id
    projectAccountId
    title
    account {
      id
      gid
      name
      aliasId
      minisiteDomain
      createNewVideosWithBlingLogo
      mfaEnabled
      createdAt
      updatedAt
      __typename
    }
    videoParts {
      name
      jsonUrl
      mods
      updatedAt
      hasVideo
      origin
      playerVersionToUse
      __typename
    }
    allowCreateDataPoint
    allowDataConnect
    allowCrmConnect
    allowSdkConnect
    allowSendFormData
    analyticsReportUrl
    createdAt
    dataFiles {
      fileName
      tag
      createdAt
      __typename
    }
    description
    fileName
    jsonChanges
    jsonVidUrl
    playerSettings {
      autoplay
      autoplay_delay
      posterFrame
      showTimeline
      storiesMode
      color_loader
      color_ctrlBtns
      color_rail
      color_progress
      color_thumb
      color_bg
      muted
      autoReplay
      showBlingsLogo
      blingsLogoColor
      loadingText
      loadingImage
      __typename
    }
    projectVersions {
      nextToken
      __typename
    }
    formData {
      nextToken
      __typename
    }
    settings
    stateJsonSchemaStr
    settingsJsonSchemaStr
    thumbS3Url
    experiments {
      id
      type
      __typename
    }
    experimentOptimizationTechnique
    analyticsEnabled
    owner
    renders {
      nextToken
      __typename
    }
    notifications {
      nextToken
      __typename
    }
    aliasId
    minisiteConfigs {
      scenes
      title
      description
      thumbUrl
      faviconUrl
      preCreate
      __typename
    }
    playerVersionToUse
    customHtml
    dynamicThumbnails {
      name
      id
      createdAt
      updatedAt
      __typename
    }
    publishedAt
    allowedOrigins
    flowDiagram {
      __typename
    }
    textToVideoScript
    instantiatedFromUseCase
    overlay {
      __typename
    }
    fontOverrides {
      family
      url
      weight
      style
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateProjectSubSubscriptionVariables,
  APITypes.OnUpdateProjectSubSubscription
>;
export const onUpdateProjectVersionSub = /* GraphQL */ `subscription OnUpdateProjectVersionSub($id: ID) {
  onUpdateProjectVersionSub(id: $id) {
    id
    accountOwner
    owner
    stateJsonSchemaStr
    settingsJsonSchemaStr
    settings
    videoParts {
      name
      jsonUrl
      mods
      updatedAt
      hasVideo
      origin
      playerVersionToUse
      __typename
    }
    updatedAt
    playerVersionToUse
    experiments {
      id
      type
      __typename
    }
    experimentOptimizationTechnique
    textToVideoScript
    flowDiagram {
      __typename
    }
    overlay {
      __typename
    }
    fontOverrides {
      family
      url
      weight
      style
      __typename
    }
    createdAt
    projectProjectVersionsId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateProjectVersionSubSubscriptionVariables,
  APITypes.OnUpdateProjectVersionSubSubscription
>;
export const onSendInstantNotification = /* GraphQL */ `subscription OnSendInstantNotification($owner: String!) {
  onSendInstantNotification(owner: $owner) {
    type
    subType
    status
    title
    message
    owner
    projectId
    extra
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnSendInstantNotificationSubscriptionVariables,
  APITypes.OnSendInstantNotificationSubscription
>;
export const onCreateUseCase = /* GraphQL */ `subscription OnCreateUseCase($filter: ModelSubscriptionUseCaseFilterInput) {
  onCreateUseCase(filter: $filter) {
    id
    name
    projectId
    images
    shortDescription
    longDescription
    thumbnail
    demoLink
    videoPreview
    lineupId
    scenes
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUseCaseSubscriptionVariables,
  APITypes.OnCreateUseCaseSubscription
>;
export const onUpdateUseCase = /* GraphQL */ `subscription OnUpdateUseCase($filter: ModelSubscriptionUseCaseFilterInput) {
  onUpdateUseCase(filter: $filter) {
    id
    name
    projectId
    images
    shortDescription
    longDescription
    thumbnail
    demoLink
    videoPreview
    lineupId
    scenes
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUseCaseSubscriptionVariables,
  APITypes.OnUpdateUseCaseSubscription
>;
export const onDeleteUseCase = /* GraphQL */ `subscription OnDeleteUseCase($filter: ModelSubscriptionUseCaseFilterInput) {
  onDeleteUseCase(filter: $filter) {
    id
    name
    projectId
    images
    shortDescription
    longDescription
    thumbnail
    demoLink
    videoPreview
    lineupId
    scenes
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUseCaseSubscriptionVariables,
  APITypes.OnDeleteUseCaseSubscription
>;
export const onCreateUseCaseTag = /* GraphQL */ `subscription OnCreateUseCaseTag(
  $filter: ModelSubscriptionUseCaseTagFilterInput
) {
  onCreateUseCaseTag(filter: $filter) {
    tag
    useCaseIds
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUseCaseTagSubscriptionVariables,
  APITypes.OnCreateUseCaseTagSubscription
>;
export const onUpdateUseCaseTag = /* GraphQL */ `subscription OnUpdateUseCaseTag(
  $filter: ModelSubscriptionUseCaseTagFilterInput
) {
  onUpdateUseCaseTag(filter: $filter) {
    tag
    useCaseIds
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUseCaseTagSubscriptionVariables,
  APITypes.OnUpdateUseCaseTagSubscription
>;
export const onDeleteUseCaseTag = /* GraphQL */ `subscription OnDeleteUseCaseTag(
  $filter: ModelSubscriptionUseCaseTagFilterInput
) {
  onDeleteUseCaseTag(filter: $filter) {
    tag
    useCaseIds
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUseCaseTagSubscriptionVariables,
  APITypes.OnDeleteUseCaseTagSubscription
>;
export const onCreateAccount = /* GraphQL */ `subscription OnCreateAccount($filter: ModelSubscriptionAccountFilterInput) {
  onCreateAccount(filter: $filter) {
    id
    gid
    name
    projects {
      nextToken
      __typename
    }
    fileuploads {
      originalName
      createdAt
      fileName
      fileStatus
      fileError
      numRecords
      numErrors
      totalRecords
      writtenRecords
      __typename
    }
    integrations {
      hubspot
      __typename
    }
    aliasId
    fonts {
      fontFamily
      __typename
    }
    accountType {
      levelName
      __typename
    }
    minisiteDomain
    createNewVideosWithBlingLogo
    mfaEnabled
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAccountSubscriptionVariables,
  APITypes.OnCreateAccountSubscription
>;
export const onUpdateAccount = /* GraphQL */ `subscription OnUpdateAccount($filter: ModelSubscriptionAccountFilterInput) {
  onUpdateAccount(filter: $filter) {
    id
    gid
    name
    projects {
      nextToken
      __typename
    }
    fileuploads {
      originalName
      createdAt
      fileName
      fileStatus
      fileError
      numRecords
      numErrors
      totalRecords
      writtenRecords
      __typename
    }
    integrations {
      hubspot
      __typename
    }
    aliasId
    fonts {
      fontFamily
      __typename
    }
    accountType {
      levelName
      __typename
    }
    minisiteDomain
    createNewVideosWithBlingLogo
    mfaEnabled
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAccountSubscriptionVariables,
  APITypes.OnUpdateAccountSubscription
>;
export const onDeleteAccount = /* GraphQL */ `subscription OnDeleteAccount($filter: ModelSubscriptionAccountFilterInput) {
  onDeleteAccount(filter: $filter) {
    id
    gid
    name
    projects {
      nextToken
      __typename
    }
    fileuploads {
      originalName
      createdAt
      fileName
      fileStatus
      fileError
      numRecords
      numErrors
      totalRecords
      writtenRecords
      __typename
    }
    integrations {
      hubspot
      __typename
    }
    aliasId
    fonts {
      fontFamily
      __typename
    }
    accountType {
      levelName
      __typename
    }
    minisiteDomain
    createNewVideosWithBlingLogo
    mfaEnabled
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAccountSubscriptionVariables,
  APITypes.OnDeleteAccountSubscription
>;
export const onCreateFormData = /* GraphQL */ `subscription OnCreateFormData($filter: ModelSubscriptionFormDataFilterInput) {
  onCreateFormData(filter: $filter) {
    id
    accountOwner
    projectFormDataId
    project {
      id
      projectAccountId
      title
      allowCreateDataPoint
      allowDataConnect
      allowCrmConnect
      allowSdkConnect
      allowSendFormData
      analyticsReportUrl
      createdAt
      description
      fileName
      jsonChanges
      jsonVidUrl
      settings
      stateJsonSchemaStr
      settingsJsonSchemaStr
      thumbS3Url
      experimentOptimizationTechnique
      analyticsEnabled
      owner
      aliasId
      playerVersionToUse
      customHtml
      publishedAt
      allowedOrigins
      textToVideoScript
      instantiatedFromUseCase
      updatedAt
      __typename
    }
    data
    sessionId
    userId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateFormDataSubscriptionVariables,
  APITypes.OnCreateFormDataSubscription
>;
export const onUpdateFormData = /* GraphQL */ `subscription OnUpdateFormData($filter: ModelSubscriptionFormDataFilterInput) {
  onUpdateFormData(filter: $filter) {
    id
    accountOwner
    projectFormDataId
    project {
      id
      projectAccountId
      title
      allowCreateDataPoint
      allowDataConnect
      allowCrmConnect
      allowSdkConnect
      allowSendFormData
      analyticsReportUrl
      createdAt
      description
      fileName
      jsonChanges
      jsonVidUrl
      settings
      stateJsonSchemaStr
      settingsJsonSchemaStr
      thumbS3Url
      experimentOptimizationTechnique
      analyticsEnabled
      owner
      aliasId
      playerVersionToUse
      customHtml
      publishedAt
      allowedOrigins
      textToVideoScript
      instantiatedFromUseCase
      updatedAt
      __typename
    }
    data
    sessionId
    userId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateFormDataSubscriptionVariables,
  APITypes.OnUpdateFormDataSubscription
>;
export const onDeleteFormData = /* GraphQL */ `subscription OnDeleteFormData($filter: ModelSubscriptionFormDataFilterInput) {
  onDeleteFormData(filter: $filter) {
    id
    accountOwner
    projectFormDataId
    project {
      id
      projectAccountId
      title
      allowCreateDataPoint
      allowDataConnect
      allowCrmConnect
      allowSdkConnect
      allowSendFormData
      analyticsReportUrl
      createdAt
      description
      fileName
      jsonChanges
      jsonVidUrl
      settings
      stateJsonSchemaStr
      settingsJsonSchemaStr
      thumbS3Url
      experimentOptimizationTechnique
      analyticsEnabled
      owner
      aliasId
      playerVersionToUse
      customHtml
      publishedAt
      allowedOrigins
      textToVideoScript
      instantiatedFromUseCase
      updatedAt
      __typename
    }
    data
    sessionId
    userId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteFormDataSubscriptionVariables,
  APITypes.OnDeleteFormDataSubscription
>;
export const onCreateNotification = /* GraphQL */ `subscription OnCreateNotification(
  $filter: ModelSubscriptionNotificationFilterInput
  $owner: String
) {
  onCreateNotification(filter: $filter, owner: $owner) {
    id
    type
    subType
    status
    title
    message
    read
    owner
    projectId
    project {
      id
      projectAccountId
      title
      allowCreateDataPoint
      allowDataConnect
      allowCrmConnect
      allowSdkConnect
      allowSendFormData
      analyticsReportUrl
      createdAt
      description
      fileName
      jsonChanges
      jsonVidUrl
      settings
      stateJsonSchemaStr
      settingsJsonSchemaStr
      thumbS3Url
      experimentOptimizationTechnique
      analyticsEnabled
      owner
      aliasId
      playerVersionToUse
      customHtml
      publishedAt
      allowedOrigins
      textToVideoScript
      instantiatedFromUseCase
      updatedAt
      __typename
    }
    createdAt
    extra
    updatedAt
    projectNotificationsId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateNotificationSubscriptionVariables,
  APITypes.OnCreateNotificationSubscription
>;
export const onUpdateNotification = /* GraphQL */ `subscription OnUpdateNotification(
  $filter: ModelSubscriptionNotificationFilterInput
  $owner: String
) {
  onUpdateNotification(filter: $filter, owner: $owner) {
    id
    type
    subType
    status
    title
    message
    read
    owner
    projectId
    project {
      id
      projectAccountId
      title
      allowCreateDataPoint
      allowDataConnect
      allowCrmConnect
      allowSdkConnect
      allowSendFormData
      analyticsReportUrl
      createdAt
      description
      fileName
      jsonChanges
      jsonVidUrl
      settings
      stateJsonSchemaStr
      settingsJsonSchemaStr
      thumbS3Url
      experimentOptimizationTechnique
      analyticsEnabled
      owner
      aliasId
      playerVersionToUse
      customHtml
      publishedAt
      allowedOrigins
      textToVideoScript
      instantiatedFromUseCase
      updatedAt
      __typename
    }
    createdAt
    extra
    updatedAt
    projectNotificationsId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateNotificationSubscriptionVariables,
  APITypes.OnUpdateNotificationSubscription
>;
export const onDeleteNotification = /* GraphQL */ `subscription OnDeleteNotification(
  $filter: ModelSubscriptionNotificationFilterInput
  $owner: String
) {
  onDeleteNotification(filter: $filter, owner: $owner) {
    id
    type
    subType
    status
    title
    message
    read
    owner
    projectId
    project {
      id
      projectAccountId
      title
      allowCreateDataPoint
      allowDataConnect
      allowCrmConnect
      allowSdkConnect
      allowSendFormData
      analyticsReportUrl
      createdAt
      description
      fileName
      jsonChanges
      jsonVidUrl
      settings
      stateJsonSchemaStr
      settingsJsonSchemaStr
      thumbS3Url
      experimentOptimizationTechnique
      analyticsEnabled
      owner
      aliasId
      playerVersionToUse
      customHtml
      publishedAt
      allowedOrigins
      textToVideoScript
      instantiatedFromUseCase
      updatedAt
      __typename
    }
    createdAt
    extra
    updatedAt
    projectNotificationsId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteNotificationSubscriptionVariables,
  APITypes.OnDeleteNotificationSubscription
>;
export const onDeleteProject = /* GraphQL */ `subscription OnDeleteProject(
  $filter: ModelSubscriptionProjectFilterInput
  $owner: String
) {
  onDeleteProject(filter: $filter, owner: $owner) {
    id
    projectAccountId
    title
    account {
      id
      gid
      name
      aliasId
      minisiteDomain
      createNewVideosWithBlingLogo
      mfaEnabled
      createdAt
      updatedAt
      __typename
    }
    videoParts {
      name
      jsonUrl
      mods
      updatedAt
      hasVideo
      origin
      playerVersionToUse
      __typename
    }
    allowCreateDataPoint
    allowDataConnect
    allowCrmConnect
    allowSdkConnect
    allowSendFormData
    analyticsReportUrl
    createdAt
    dataFiles {
      fileName
      tag
      createdAt
      __typename
    }
    description
    fileName
    jsonChanges
    jsonVidUrl
    playerSettings {
      autoplay
      autoplay_delay
      posterFrame
      showTimeline
      storiesMode
      color_loader
      color_ctrlBtns
      color_rail
      color_progress
      color_thumb
      color_bg
      muted
      autoReplay
      showBlingsLogo
      blingsLogoColor
      loadingText
      loadingImage
      __typename
    }
    projectVersions {
      nextToken
      __typename
    }
    formData {
      nextToken
      __typename
    }
    settings
    stateJsonSchemaStr
    settingsJsonSchemaStr
    thumbS3Url
    experiments {
      id
      type
      __typename
    }
    experimentOptimizationTechnique
    analyticsEnabled
    owner
    renders {
      nextToken
      __typename
    }
    notifications {
      nextToken
      __typename
    }
    aliasId
    minisiteConfigs {
      scenes
      title
      description
      thumbUrl
      faviconUrl
      preCreate
      __typename
    }
    playerVersionToUse
    customHtml
    dynamicThumbnails {
      name
      id
      createdAt
      updatedAt
      __typename
    }
    publishedAt
    allowedOrigins
    flowDiagram {
      __typename
    }
    textToVideoScript
    instantiatedFromUseCase
    overlay {
      __typename
    }
    fontOverrides {
      family
      url
      weight
      style
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteProjectSubscriptionVariables,
  APITypes.OnDeleteProjectSubscription
>;
export const onCreateProjectVersion = /* GraphQL */ `subscription OnCreateProjectVersion(
  $filter: ModelSubscriptionProjectVersionFilterInput
  $owner: String
) {
  onCreateProjectVersion(filter: $filter, owner: $owner) {
    id
    accountOwner
    owner
    stateJsonSchemaStr
    settingsJsonSchemaStr
    settings
    videoParts {
      name
      jsonUrl
      mods
      updatedAt
      hasVideo
      origin
      playerVersionToUse
      __typename
    }
    updatedAt
    playerVersionToUse
    experiments {
      id
      type
      __typename
    }
    experimentOptimizationTechnique
    textToVideoScript
    flowDiagram {
      __typename
    }
    overlay {
      __typename
    }
    fontOverrides {
      family
      url
      weight
      style
      __typename
    }
    createdAt
    projectProjectVersionsId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateProjectVersionSubscriptionVariables,
  APITypes.OnCreateProjectVersionSubscription
>;
export const onDeleteProjectVersion = /* GraphQL */ `subscription OnDeleteProjectVersion(
  $filter: ModelSubscriptionProjectVersionFilterInput
  $owner: String
) {
  onDeleteProjectVersion(filter: $filter, owner: $owner) {
    id
    accountOwner
    owner
    stateJsonSchemaStr
    settingsJsonSchemaStr
    settings
    videoParts {
      name
      jsonUrl
      mods
      updatedAt
      hasVideo
      origin
      playerVersionToUse
      __typename
    }
    updatedAt
    playerVersionToUse
    experiments {
      id
      type
      __typename
    }
    experimentOptimizationTechnique
    textToVideoScript
    flowDiagram {
      __typename
    }
    overlay {
      __typename
    }
    fontOverrides {
      family
      url
      weight
      style
      __typename
    }
    createdAt
    projectProjectVersionsId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteProjectVersionSubscriptionVariables,
  APITypes.OnDeleteProjectVersionSubscription
>;
export const onCreateServerRender = /* GraphQL */ `subscription OnCreateServerRender(
  $filter: ModelSubscriptionServerRenderFilterInput
  $cognitoUserIdentifier: String
) {
  onCreateServerRender(
    filter: $filter
    cognitoUserIdentifier: $cognitoUserIdentifier
  ) {
    id
    projectId
    project {
      id
      projectAccountId
      title
      allowCreateDataPoint
      allowDataConnect
      allowCrmConnect
      allowSdkConnect
      allowSendFormData
      analyticsReportUrl
      createdAt
      description
      fileName
      jsonChanges
      jsonVidUrl
      settings
      stateJsonSchemaStr
      settingsJsonSchemaStr
      thumbS3Url
      experimentOptimizationTechnique
      analyticsEnabled
      owner
      aliasId
      playerVersionToUse
      customHtml
      publishedAt
      allowedOrigins
      textToVideoScript
      instantiatedFromUseCase
      updatedAt
      __typename
    }
    scenes
    data
    env
    outputFileName
    quality
    frameToStart
    frameToEnd
    format
    status {
      ready
      status
      downloadLink
      code
      __typename
    }
    cognitoUserIdentifier
    cognitoGroupOwner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateServerRenderSubscriptionVariables,
  APITypes.OnCreateServerRenderSubscription
>;
export const onUpdateServerRender = /* GraphQL */ `subscription OnUpdateServerRender(
  $filter: ModelSubscriptionServerRenderFilterInput
  $cognitoUserIdentifier: String
) {
  onUpdateServerRender(
    filter: $filter
    cognitoUserIdentifier: $cognitoUserIdentifier
  ) {
    id
    projectId
    project {
      id
      projectAccountId
      title
      allowCreateDataPoint
      allowDataConnect
      allowCrmConnect
      allowSdkConnect
      allowSendFormData
      analyticsReportUrl
      createdAt
      description
      fileName
      jsonChanges
      jsonVidUrl
      settings
      stateJsonSchemaStr
      settingsJsonSchemaStr
      thumbS3Url
      experimentOptimizationTechnique
      analyticsEnabled
      owner
      aliasId
      playerVersionToUse
      customHtml
      publishedAt
      allowedOrigins
      textToVideoScript
      instantiatedFromUseCase
      updatedAt
      __typename
    }
    scenes
    data
    env
    outputFileName
    quality
    frameToStart
    frameToEnd
    format
    status {
      ready
      status
      downloadLink
      code
      __typename
    }
    cognitoUserIdentifier
    cognitoGroupOwner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateServerRenderSubscriptionVariables,
  APITypes.OnUpdateServerRenderSubscription
>;
export const onDeleteServerRender = /* GraphQL */ `subscription OnDeleteServerRender(
  $filter: ModelSubscriptionServerRenderFilterInput
  $cognitoUserIdentifier: String
) {
  onDeleteServerRender(
    filter: $filter
    cognitoUserIdentifier: $cognitoUserIdentifier
  ) {
    id
    projectId
    project {
      id
      projectAccountId
      title
      allowCreateDataPoint
      allowDataConnect
      allowCrmConnect
      allowSdkConnect
      allowSendFormData
      analyticsReportUrl
      createdAt
      description
      fileName
      jsonChanges
      jsonVidUrl
      settings
      stateJsonSchemaStr
      settingsJsonSchemaStr
      thumbS3Url
      experimentOptimizationTechnique
      analyticsEnabled
      owner
      aliasId
      playerVersionToUse
      customHtml
      publishedAt
      allowedOrigins
      textToVideoScript
      instantiatedFromUseCase
      updatedAt
      __typename
    }
    scenes
    data
    env
    outputFileName
    quality
    frameToStart
    frameToEnd
    format
    status {
      ready
      status
      downloadLink
      code
      __typename
    }
    cognitoUserIdentifier
    cognitoGroupOwner
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteServerRenderSubscriptionVariables,
  APITypes.OnDeleteServerRenderSubscription
>;
