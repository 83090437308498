import "./StaticTemplate.scss";
import { Button } from "antd";
import { PATHS } from "../../../PATHS";
import AI from "../../../assets/AI-modal-content.png";
import AE from "../../../assets/AE-modal-content.png";

export interface StaticTemplateProps {
  props: {
    image: "AI" | "AE";
    title: string;
    titleDetail?: string;
    content: Array<{ subtitle: string; text: string }>;
    footerTitle: React.ReactNode;
    footerText?: string;
    footerSubText?: {
      text: string;
      textBold?: string;
    };
    buttonText?: string;
    redirectPage?: string;
    onButtonClick?: () => void;
  };
}

function getImagePath(imageKey: "AI" | "AE"): string {
  switch (imageKey) {
    case "AI":
      return AI;
    case "AE":
      return AE;
    default:
      return "";
  }
}

export default function StaticTemplate({ props }: StaticTemplateProps) {
  const handleButtonClick = () => {
    if (props.redirectPage) {
      const isInternalPath = (Object.values(PATHS) as string[]).includes(
        props.redirectPage
      );

      if (isInternalPath) {
        window.location.href =
          window.location.protocol +
          "//" +
          window.location.host +
          "/" +
          props.redirectPage;
      } else {
        window.open(props.redirectPage, "_blank");
      }
    } else if (props.onButtonClick) {
      props.onButtonClick();
    }
  };

  return (
    <div className="use-case-wrapper">
      <div className="left-side">
        <img src={getImagePath(props.image)} alt={props.image} />
      </div>
      <div className="right-side">
        <div>
          <div className="header">
            <h1>{props.title}</h1>
            {props.titleDetail && (
              <span className="shine-detail">{props.titleDetail}</span>
            )}
          </div>
          <div>
            {props.content.map((item, index) => (
              <div className="step-container" key={index}>
                <div className="countage">
                  <span>{index + 1}</span>
                  {index !== props.content.length - 1 && (
                    <div className="line-detail"></div>
                  )}
                </div>
                <div>
                  <h2>{item.subtitle}</h2>
                  <p>{item.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="footer">
          <p>{props.footerTitle}</p>
          {props.footerSubText && (
            <p>
              {props.footerSubText.text}{" "}
              <span style={{ fontWeight: "bold" }}>
                {props.footerSubText.textBold}
              </span>
            </p>
          )}
          {props.buttonText && (
            <Button className="use-template-btn" onClick={handleButtonClick}>
              {props.buttonText}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
