import React, { useState, useEffect } from "react";
import "./Carousel.scss";
import { LeftArrow, RightArrow } from "../../icons-and-animations/icons";
import SdkPlayer from "../SdkPlayer";

export type CarouselProps = {
  slides:
    | Array<{
        type: "image" | "player";
        content: string | any;
      }>
    | [];
  showPagination: boolean;
};

const Carousel: React.FC<CarouselProps> = ({ slides, showPagination }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState<"next" | "prev">("next");
  const [key, setKey] = useState(0);
  const [loadingStates, setLoadingStates] = useState<Record<number, boolean>>(
    {}
  );
  const totalSlides = slides.length;

  useEffect(() => {
    if (slides[currentIndex]?.type === "image") {
      setLoadingStates((prev) => ({
        ...prev,
        [currentIndex]: true,
      }));
    }
  }, [currentIndex, slides]);

  const goToNext = () => {
    setDirection("next");
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalSlides);
    setKey((prev) => prev + 1);
  };

  const goToPrev = () => {
    setDirection("prev");
    setCurrentIndex((prevIndex) => (prevIndex - 1 + totalSlides) % totalSlides);
    setKey((prev) => prev + 1);
  };

  const handleImageLoad = (index: number) => {
    setLoadingStates((prev) => ({
      ...prev,
      [index]: false,
    }));
  };

  const renderSlide = (slide: (typeof slides)[0], index: number) => {
    if (slide.type === "image") {
      return (
        <div className="image-container">
          {loadingStates[index] && (
            <div className="loader">
              <div className="spinner"></div>
            </div>
          )}
          <img
            src={slide.content}
            alt={`Slide ${index + 1}`}
            onLoad={() => handleImageLoad(index)}
          />
        </div>
      );
    } else {
      return <SdkPlayer sdkParams={slide.content} />;
    }
  };

  return (
    <div className="carousel">
      <div className="carousel-container">
        <div className="content-wrapper">
          <div
            key={key}
            className={`carousel-track ${direction}`}
            style={{ height: "624px" }}
          >
            {renderSlide(slides[currentIndex], currentIndex)}
          </div>
        </div>
      </div>

      {showPagination && totalSlides > 1 ? (
        <div className="carousel-pagination">
          <button
            className="arrow"
            onClick={goToPrev}
            aria-label="Previous Slide"
          >
            <LeftArrow />
          </button>
          <div className="dots">
            {slides.map((_, index) => (
              <span
                key={index}
                className={`dot ${index === currentIndex ? "active" : ""}`}
              ></span>
            ))}
          </div>
          <button className="arrow" onClick={goToNext} aria-label="Next Slide">
            <RightArrow />
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default Carousel;
