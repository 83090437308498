import {
  Divider,
  InputNumber,
  List,
  Popover,
  Radio,
  Select,
  Space,
} from "antd";
import { ColorPicker } from "../../../../../../components/ColorPicker";
import { useEffect, useState } from "react";
import { ConfigV2 } from "../DynamicThumbnailForm";
import { getFontsFromServer } from "../../../../../../stores/project/dynamicThumbnailTools";
import { useMst } from "../../../../../../stores/Root";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { OpenLinkIcon } from "../../../../../../assets/Icons";

interface TextProps {
  textIndex: number;
  config: ConfigV2;
  setConfig: (config: ConfigV2) => void;
}
type Variant = {
  name: string;
  weight: number;
  style: string;
};

type Font = {
  fontFamily: string;
  variants: Variant[];
};

type AllowedValuesToAlign = {
  value: 0 | 50 | 100;
  anchor: "start" | "middle" | "end";
};

const TextStyleSelector = ({ textIndex, config, setConfig }: TextProps) => {
  const [weightItems, setWeightItems] = useState<
    { value: string; label: string }[]
  >([]);
  const [fonts, setFonts] = useState<Font[]>([]);

  const { accountFonts } = useMst((store) => {
    return {
      accountFonts:
        store.userStore.accountFromId(
          store.projectsStore.selectedProject?.projectAccountId
        )?.fonts || [],
    };
  });

  const getFonts = async () => {
    try {
      const response = await getFontsFromServer();
      const fonts = [...response, ...accountFonts];
      const fontMap: any = {};
      fonts.forEach((font) => {
        //Merge fonts from both account and API
        if (fontMap[font.fontFamily]) {
          fontMap[font.fontFamily] = [
            ...fontMap[font.fontFamily],
            ...(font.variants || font.variant),
          ];
        } else {
          fontMap[font.fontFamily] = font.variants || font.variant;
        }
      });

      const consolidatedFonts = Object.keys(fontMap).map((fontFamily) => {
        return {
          fontFamily: fontFamily,
          variants: fontMap[fontFamily],
        };
      });
      setFonts(consolidatedFonts);
    } catch (err) {
      console.log(err);
    }
  };

  const updateWeightItems = (fontFamily: string) => {
    const selectedFont = fonts.find((font) => font.fontFamily === fontFamily);
    if (selectedFont) {
      const uniqueWeights = selectedFont.variants.reduce<
        { value: string; label: string }[]
      >((acc, variant) => {
        // If the weight is already added, skip this variant
        if (acc.some((item) => item.value === variant.weight.toString()))
          return acc;
        acc.push({
          value: variant.weight.toString(),
          label: variant.name,
        });
        return acc;
      }, []);

      // Sort the weights
      uniqueWeights.sort((a, b) => parseInt(a.value) - parseInt(b.value));
      setWeightItems(uniqueWeights);
    } else {
      setWeightItems([]);
    }
  };

  const getFontItems = () => {
    // Filtered alphabetically
    return fonts
      .map((font) => ({
        value: font.fontFamily,
        label: font.fontFamily,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  };

  useEffect(() => {
    getFonts();
  }, []);

  useEffect(() => {
    if (config.texts[textIndex].fontFamily && fonts.length > 0) {
      updateWeightItems(config.texts[textIndex].fontFamily);
    }
  }, [fonts]);

  return (
    <>
      <Space className="textSelectorSpace">
        <Select
          options={getFontItems()}
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Divider style={{ margin: "0" }} />
              <List.Item
                className="final-list-item"
                onClick={() => {
                  window.open(
                    window.location.protocol +
                      "//" +
                      window.location.host +
                      "/settings",
                    "_blank"
                  );
                }}
              >
                <OpenLinkIcon width={22} />
                Add fonts in Settings
              </List.Item>
            </div>
          )}
          showSearch
          style={{ width: "175px" }}
          value={config.texts[textIndex].fontFamily}
          dropdownStyle={{ minWidth: "200px" }}
          placeholder="Select a Font"
          optionFilterProp="label"
          onChange={(e) => {
            updateWeightItems(e);
            const nextState: ConfigV2 = JSON.parse(JSON.stringify(config));
            nextState.texts[textIndex].fontFamily = e;
            setConfig(nextState);
            console.log(nextState);
          }}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
        />

        <Select
          options={weightItems}
          showSearch
          style={{ width: "175px" }}
          value={
            config.texts[textIndex].weight &&
            weightItems.some(
              (item) => item.value === config.texts[textIndex].weight.toString()
            )
              ? config.texts[textIndex].weight.toString()
              : weightItems[0]?.value
          }
          dropdownStyle={{ minWidth: "200px" }}
          placeholder="Select a Weight"
          optionFilterProp="label"
          onChange={(e) => {
            const nextState: ConfigV2 = JSON.parse(JSON.stringify(config));
            nextState.texts[textIndex].weight = e;
            setConfig(nextState);
            console.log(nextState);
          }}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
        />
      </Space>

      <Space className="textSelectorSpace">
        <div className="label-div">
          <label>Font size</label>
          <InputNumber
            style={{ width: "65px" }}
            value={config.texts[textIndex].fontSize}
            onChange={(e: any) => {
              const nextState = JSON.parse(JSON.stringify(config));
              nextState.texts[textIndex].fontSize = e;

              setConfig(nextState);
            }}
          />
        </div>
        <div className="label-div">
          <label> Breakpoint </label>
          <InputNumber
            style={{ width: "65px" }}
            value={config.texts[textIndex].breakpoint}
            onChange={(a) => {
              const nextState = JSON.parse(JSON.stringify(config));
              nextState.texts[textIndex].breakpoint = a !== null ? a : 0;
              setConfig(nextState);
            }}
          />
          <Popover
            className="tooltip"
            placement="right"
            overlayInnerStyle={{ width: "250px" }}
            style={{ zIndex: "0" }}
            getPopupContainer={(trigger) =>
              trigger?.parentElement || document.body
            }
            content={
              <div className="extra-info-popover">
                <b>How breakpoints work:</b>
                <div>
                  The font size will automatically reduce for text that exceeds
                  the specified breakpoint length.
                </div>
              </div>
            }
          >
            <div>
              <QuestionCircleOutlined
                style={{ color: "var(--blings_icon_gray)", cursor: "pointer" }}
              />
            </div>
          </Popover>
        </div>
        <div className="label-div">
          <label>Color</label>
          <ColorPicker
            color={config.texts[textIndex].color}
            onChange={(e: any) => {
              const nextState = JSON.parse(JSON.stringify(config));
              nextState.texts[textIndex].color = e.hex;
              setConfig(nextState);
            }}
          />
        </div>
        <div className="label-div">
          <label>Stroke</label>
          <ColorPicker
            disableAlpha={false}
            color={
              config.texts[textIndex].stroke.color +
              Math.round(config.texts[textIndex].stroke.opacity * 255)
                .toString(16)
                .padStart(2, "0")
            }
            onChange={(e: any) => {
              const newColor = e.hex;
              const newOpacity = e.rgb.a < 0.07 ? 0 : e.rgb.a;
              const nextState = JSON.parse(JSON.stringify(config));
              nextState.texts[textIndex].stroke.color = newColor;
              nextState.texts[textIndex].stroke.opacity = newOpacity;
              setConfig(nextState);
            }}
          />
        </div>
      </Space>

      <Space className="textSelectorSpace">
        <div className="label-div">
          <label>Horizontal position</label>
          <div>
            <InputNumber
              min={0}
              max={100}
              step={0.1}
              formatter={(value) => `${value || 0}%`}
              parser={(value) => parseFloat((value || "").replace("%", ""))}
              value={config.texts[textIndex].position.x}
              onChange={(e: any) => {
                const nextState: ConfigV2 = JSON.parse(JSON.stringify(config));
                nextState.texts[textIndex].position.x = e;
                setConfig(nextState);
              }}
            />
          </div>
        </div>
        <div className="label-div">
          <label>Vertical position</label>
          <div>
            <InputNumber
              min={0}
              max={100}
              step={0.1}
              formatter={(value) => `${value || 0}%`}
              parser={(value) => parseFloat((value || "").replace("%", ""))}
              value={config.texts[textIndex].position.y}
              onChange={(e: any) => {
                const nextState: ConfigV2 = JSON.parse(JSON.stringify(config));
                nextState.texts[textIndex].position.y = e;
                setConfig(nextState);
              }}
            />
          </div>
        </div>
      </Space>
      <Space className="textSelectorSpace">
        <div className="label-div">
          <label>Alignment</label>
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
            block
            value={config.texts[textIndex].anchor}
            onChange={(e) => {
              const nextState: ConfigV2 = JSON.parse(JSON.stringify(config));
              nextState.texts[textIndex].anchor = e.target.value;
              setConfig(nextState);
            }}
          >
            <Radio.Button value="start">Left</Radio.Button>
            <Radio.Button value="middle">Center</Radio.Button>
            <Radio.Button value="end">Right</Radio.Button>
          </Radio.Group>
        </div>
      </Space>
    </>
  );
};

export default TextStyleSelector;
