import { get, post } from "aws-amplify/api";
import { types, flow } from "mobx-state-tree";
import {
  NEW_REST_API,
  REST_API_V2__GET_USE_CASES,
  REST_API_V2__INSTANTIATE_USE_CASE,
} from "../../consts";
import { FontInput, OverlayInput, UseCase, UseCaseTag } from "../../API";
import { IJSONObject } from "@blings/blings-player";

export const UseCasesModel = types
  .model("UseCasesModel", {
    tags: types.optional(types.array(types.frozen<UseCaseTag>()), []),
    useCases: types.optional(types.array(types.frozen<UseCase>()), []),
    useCasesLoading: types.optional(types.boolean, true),
  })
  .actions((self) => ({
    getUseCases: flow(function* () {
      try {
        const response = yield get({
          apiName: NEW_REST_API,
          path: REST_API_V2__GET_USE_CASES,
        }).response;
        const data = yield response.body.json();
        self.useCases.replace(data.useCases);
        self.tags.replace(data.tags);
        self.useCasesLoading = false;
      } catch (e) {
        console.error(e);
      }
    }),
  }))
  .actions((self) => ({
    afterCreate: () => {
      self.getUseCases();
    },
    setIsLoading(isLoading: boolean) {
      self.useCasesLoading = isLoading;
    },
    getUseCasesFromTags: (tags: Array<string>) => {
      if (tags.length === 0) return self.useCases;
      const useCaseTags = self.tags.filter((tag) => tags.includes(tag.tag));
      const useCaseIds = Array.from(
        new Set(useCaseTags.flatMap((tag) => tag.useCaseIds))
      );
      return self.useCases.filter((useCases) =>
        useCaseIds.includes(useCases.id)
      );
    },

    instantiateUseCase: async (
      accountId: string,
      useCaseId: string,
      liveControl: IJSONObject,
      fontOverrides: Array<FontInput>,
      overlay: OverlayInput,
      title?: string
    ) =>{
      try {
        const response = await post({
          apiName: NEW_REST_API,
          path: REST_API_V2__INSTANTIATE_USE_CASE(accountId, useCaseId),
          options: {
            body: JSON.parse(
              JSON.stringify({
                liveControl,
                fontOverrides,
                overlay,
                title,
              })
            ),
          },
        }).response;
        const res = (await response.body.json()) as unknown as { id?: string };
        if (res?.id) return res.id;
        return undefined;
      } catch (e) {
        console.error(e);
      }
      return undefined;
    },
  }))
  .views((self) => ({
    useCasesList: () => {
      return self.useCases;
    },
    useCasesTagsList: () => {
      return self.tags;
    },
    isUseCasesLoading: () => {
      return self.useCasesLoading;
    },
  }));
